@charset "UTF-8";
@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #3f51b5;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #ff4081;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #3f51b5;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ff4081;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #3f51b5;
  --mdc-linear-progress-track-color: rgba(63, 81, 181, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(63, 81, 181, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(63, 81, 181, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(63, 81, 181, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ff4081;
  --mdc-linear-progress-track-color: rgba(255, 64, 129, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 64, 129, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 64, 129, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 64, 129, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(244, 67, 54, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(244, 67, 54, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(244, 67, 54, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #3f51b5;
  --mdc-filled-text-field-focus-active-indicator-color: #3f51b5;
  --mdc-filled-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #3f51b5;
  --mdc-outlined-text-field-focus-outline-color: #3f51b5;
  --mdc-outlined-text-field-focus-label-text-color: rgba(63, 81, 181, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #f44336);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(63, 81, 181, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(255, 64, 129, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(244, 67, 54, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #ff4081;
  --mdc-filled-text-field-focus-active-indicator-color: #ff4081;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
  --mdc-outlined-text-field-caret-color: #ff4081;
  --mdc-outlined-text-field-focus-outline-color: #ff4081;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 64, 129, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(63, 81, 181, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 64, 129, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #3f51b5;
  --mdc-chip-elevated-disabled-container-color: #3f51b5;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff4081;
  --mdc-chip-elevated-disabled-container-color: #ff4081;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #3949ab;
  --mdc-switch-selected-handle-color: #3949ab;
  --mdc-switch-selected-hover-state-layer-color: #3949ab;
  --mdc-switch-selected-pressed-state-layer-color: #3949ab;
  --mdc-switch-selected-focus-handle-color: #1a237e;
  --mdc-switch-selected-hover-handle-color: #1a237e;
  --mdc-switch-selected-pressed-handle-color: #1a237e;
  --mdc-switch-selected-focus-track-color: #7986cb;
  --mdc-switch-selected-hover-track-color: #7986cb;
  --mdc-switch-selected-pressed-track-color: #7986cb;
  --mdc-switch-selected-track-color: #7986cb;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #d81b60;
  --mdc-switch-selected-handle-color: #d81b60;
  --mdc-switch-selected-hover-state-layer-color: #d81b60;
  --mdc-switch-selected-pressed-state-layer-color: #d81b60;
  --mdc-switch-selected-focus-handle-color: #880e4f;
  --mdc-switch-selected-hover-handle-color: #880e4f;
  --mdc-switch-selected-pressed-handle-color: #880e4f;
  --mdc-switch-selected-focus-track-color: #f06292;
  --mdc-switch-selected-hover-track-color: #f06292;
  --mdc-switch-selected-pressed-track-color: #f06292;
  --mdc-switch-selected-track-color: #f06292;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #3f51b5;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #ff4081;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #3f51b5;
  --mdc-slider-focus-handle-color: #3f51b5;
  --mdc-slider-hover-handle-color: #3f51b5;
  --mdc-slider-active-track-color: #3f51b5;
  --mdc-slider-inactive-track-color: #3f51b5;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #3f51b5;
  --mat-mdc-slider-ripple-color: #3f51b5;
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ff4081;
  --mdc-slider-focus-handle-color: #ff4081;
  --mdc-slider-hover-handle-color: #ff4081;
  --mdc-slider-active-track-color: #ff4081;
  --mdc-slider-inactive-track-color: #ff4081;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff4081;
  --mat-mdc-slider-ripple-color: #ff4081;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 64, 129, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 64, 129, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mat-mdc-slider-ripple-color: #f44336;
  --mat-mdc-slider-hover-ripple-color: rgba(244, 67, 54, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(244, 67, 54, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3f51b5;
  --mdc-radio-selected-hover-icon-color: #3f51b5;
  --mdc-radio-selected-icon-color: #3f51b5;
  --mdc-radio-selected-pressed-icon-color: #3f51b5;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff4081;
  --mdc-radio-selected-hover-icon-color: #ff4081;
  --mdc-radio-selected-icon-color: #ff4081;
  --mdc-radio-selected-pressed-icon-color: #ff4081;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #3f51b5;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #3f51b5;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #3f51b5;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #3f51b5;
  --mat-tab-header-active-ripple-color: #3f51b5;
  --mat-tab-header-inactive-ripple-color: #3f51b5;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #3f51b5;
  --mat-tab-header-active-hover-label-text-color: #3f51b5;
  --mat-tab-header-active-focus-indicator-color: #3f51b5;
  --mat-tab-header-active-hover-indicator-color: #3f51b5;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #ff4081;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #ff4081;
  --mat-tab-header-active-ripple-color: #ff4081;
  --mat-tab-header-inactive-ripple-color: #ff4081;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff4081;
  --mat-tab-header-active-hover-label-text-color: #ff4081;
  --mat-tab-header-active-focus-indicator-color: #ff4081;
  --mat-tab-header-active-hover-indicator-color: #ff4081;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #3f51b5;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #ff4081;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #ff4081;
  --mdc-checkbox-selected-hover-icon-color: #ff4081;
  --mdc-checkbox-selected-icon-color: #ff4081;
  --mdc-checkbox-selected-pressed-icon-color: #ff4081;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff4081;
  --mdc-checkbox-selected-hover-state-layer-color: #ff4081;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff4081;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #3f51b5;
  --mdc-checkbox-selected-hover-icon-color: #3f51b5;
  --mdc-checkbox-selected-icon-color: #3f51b5;
  --mdc-checkbox-selected-pressed-icon-color: #3f51b5;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-hover-state-layer-color: #3f51b5;
  --mdc-checkbox-selected-pressed-state-layer-color: #3f51b5;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #3f51b5;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ff4081;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #3f51b5;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ff4081;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #3f51b5;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ff4081;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #3f51b5;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ff4081;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #3f51b5;
  --mat-mdc-button-persistent-ripple-color: #3f51b5;
  --mat-mdc-button-ripple-color: rgba(63, 81, 181, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ff4081;
  --mat-mdc-button-persistent-ripple-color: #ff4081;
  --mat-mdc-button-ripple-color: rgba(255, 64, 129, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #3f51b5;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ff4081;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #ff4081;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #3f51b5;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ff4081;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #3f51b5;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #ff4081;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #3f51b5;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(63, 81, 181, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(63, 81, 181, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #3f51b5;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff4081;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 64, 129, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 64, 129, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 64, 129, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #3f51b5;
}
.mat-icon.mat-accent {
  --mat-icon-color: #ff4081;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #3f51b5;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #3f51b5;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #3f51b5;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff4081;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff4081;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff4081;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #3f51b5;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #ff4081;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

:root {
  --fontFamily: "Open Sans", sans-serif;
  --headingFontFamily: "Raleway", sans-serif;
  --heraBlueColor: #757fef;
  --aareRiverColor: #00b69b;
  --blackColor: #260944;
  --paragraphColor: /* #5b5b98 */ #262d48;
  --primaryRedColor: #b92121;
  --primaryYellowColor: #ffb522;
  --primaryWhiteColor: #dfedf4;
  --primaryBlueColor: #262d48;
  --secondaryBlueColor: #6ccef7;
  --tertiaryBlueColor: #286ac0;
  --subtitleBlueColor: #252d4a;
  --primaryOrangeColor: #ff6d39;
  --whiteColor: #ffffff;
  --fontSize: 15px;
  --transition: 0.5s;
  --borderBoxShadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --borderWhiteBoxShadow: rgba(255, 255, 255, 0.05) 0px 6px 24px 0px,
    rgba(255, 255, 255, 0.08) 0px 0px 0px 1px;
}

*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  color: var(--blackColor);
  background-color: #ecedee;
  font-size: var(--fontSize);
  font-family: var(--fontFamily) !important;
}

.text-danger {
  color: red;
}

.invalid-input {
  border-color: red;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--blackColor);
  font-weight: 700;
  font-family: var(--headingFontFamily) !important;
  margin-top: 0;
  margin-bottom: 15px;
}
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

.h1,
h1 {
  font-size: 38px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 26px;
}

.h4,
h4 {
  font-size: 22px;
}

.h5,
h5 {
  font-size: 18px;
}

.h6,
h6 {
  font-size: 14px;
}

a {
  transition: var(--transition);
  color: var(--primaryBlueColor);
  text-decoration: none;
  outline: 0 !important;
}
a:hover {
  text-decoration: none;
  color: var(--primaryOrangeColor);
}

.d-table {
  width: 100%;
  height: 100%;
}
.d-table-cell {
  vertical-align: middle;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  color: var(--paragraphColor);
  font-size: var(--fontSize);
  line-height: 1.8;
  margin-top: 0;
  margin-bottom: 15px;
}
p:last-child {
  margin-bottom: 0;
}

input {
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
}

button {
  font-family: var(--fontFamily) !important;
  font-size: var(--fontSize) !important;
}

.default-btn {
  border: 0;
  font-size: 13.5px;
  border-radius: 5px;
  color: var(--whiteColor);
  padding: 11px 31px 11px 15px;
  transition: var(--transition);
  background-color: var(--heraBlueColor);
}
.default-btn i {
  transform: translateY(-50%);
  position: absolute;
  margin-top: 2px;
  line-height: 1;
  right: 15px;
  top: 50%;
}
.default-btn:hover {
  background-color: var(--aareRiverColor);
  color: var(--whiteColor);
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-table-row {
  display: table-row !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.align-items-start {
  align-items: start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: end !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.ms-auto {
  margin-left: auto !important;
}

.me-auto {
  margin-right: auto !important;
}

.text-center {
  text-align: center !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.w-100 {
  width: 100% !important;
}

.bg-white {
  background-color: var(--whiteColor) !important;
}

.bg-main-color {
  background-color: var(--heraBlueColor) !important;
}

.bg-main-color-yellow {
  background-color: var(--primaryYellowColor) !important;
}

.bg-main-color-red {
  background-color: var(--primaryRedColor) !important;
}

.bg-main-color-blue {
  background-color: var(--primaryBlueColor) !important;
  color: white !important;
}

.bg-main-color-blue-button {
  background-color: var(--tertiaryBlueColor) !important;
}

.bg-color-blue-button {
  background-color: var(--tertiaryBlueColor) !important;
  color: white !important;
}

.bg-main-color-orange-button {
  background-color: var(--primaryOrangeColor) !important;
  color: white !important;
}

.bg-main-color-white-button {
  background-color: var(--primaryWhiteColor) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.title-color {
  color: var(--tertiaryBlueColor);
}

.border-none {
  border: none !important;
}

.border-top-radius {
  border-radius: 10px 10px 0 0 !important;
}

.border-bottom-radius {
  border-radius: 0 0 10px 10px !important;
}

.border-radius {
  border-radius: 10px !important;
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.text-white {
  color: #ffffff !important;
}

.p-0 {
  padding: 0 !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-5 {
  padding: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.ps-5 {
  padding-left: 5px !important;
}

.ps-10 {
  padding-left: 10px !important;
}

.ps-15 {
  padding-left: 15px !important;
}

.ps-20 {
  padding-left: 20px !important;
}

.ps-25 {
  padding-left: 25px !important;
}

.ps-30 {
  padding-left: 30px !important;
}

.pe-5 {
  padding-right: 5px !important;
}

.pe-10 {
  padding-right: 10px !important;
}

.pe-15 {
  padding-right: 15px !important;
}

.pe-20 {
  padding-right: 20px !important;
}

.pe-25 {
  padding-right: 25px !important;
}

.pe-30 {
  padding-right: 30px !important;
}

.m-0 {
  margin: 0 !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-5 {
  margin-left: 5px !important;
}

.me-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ms-15 {
  margin-left: 15px !important;
}

.ms-20 {
  margin-left: 20px !important;
}

.ms-25 {
  margin-left: 25px !important;
}

.ms-30 {
  margin-left: 30px !important;
}

.pe-10 {
  padding-right: 10px !important;
}

.pe-12 {
  padding-right: 12px !important;
}

.ps-12 {
  padding-left: 12px !important;
}

.pe-12 {
  padding-right: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.me-5 {
  margin-right: 5px !important;
}

.me-10 {
  margin-right: 10px !important;
}

.ms-10 {
  margin-left: 10px !important;
}

.me-12 {
  margin-right: 12px !important;
}

.ms-12 {
  margin-left: 12px !important;
}

.me-15 {
  margin-right: 15px !important;
}

.me-20 {
  margin-right: 20px !important;
}

.me-25 {
  margin-right: 25px !important;
}

.me-30 {
  margin-right: 30px !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.box-shadow {
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07) !important;
}

.bg-gray {
  background-color: #f7f8f7 !important;
}

.main-color {
  color: var(--heraBlueColor) !important;
}

.bg-light-gray {
  background-color: #f7faff !important;
}

.tertiary-blue-color {
  color: var(--tertiaryBlueColor) !important;
}

.subtitle-card-color {
  color: var(--subtitleBlueColor) !important;
}

.gray-color {
  color: #5b5b98 !important;
}

.orange-color {
  color: var(--primaryOrangeColor) !important;
}

.blue-color {
  color: var(--secondaryBlueColor) !important;
}

.muted-color {
  color: #a9a9c8 !important;
}

.text-decoration-none {
  text-decoration: none;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12.5px;
  margin-right: -12.5px;
}
.row > * {
  width: 100%;
  flex-shrink: 0;
  max-width: 100%;
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-2\/5 {
  flex: 0 0 auto;
  width: 20%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.main-hover-color:hover {
  color: var(--heraBlueColor) !important;
}

.w-35 {
  width: 35px !important;
}

.h-35 {
  height: 35px !important;
}

.w-85 {
  width: 85px !important;
}

.h-85 {
  height: 85px !important;
}

.w-75 {
  width: 75px !important;
}

.h-75 {
  height: 75px !important;
}

.w-65 {
  width: 65px !important;
}

.h-65 {
  height: 65px !important;
}

.w-55 {
  width: 55px !important;
}

.h-55 {
  height: 55px !important;
}

.w-45 {
  width: 45px !important;
}

.h-45 {
  height: 45px !important;
}

.lh-35 {
  line-height: 35px !important;
}

.lh-38 {
  line-height: 38px !important;
}

.lh-1 {
  line-height: 1 !important;
}

.bg-main-hover-color:hover {
  color: var(--whiteColor) !important;
  background-color: var(--heraBlueColor) !important;
}

.h-auto {
  height: auto !important;
}

.small,
small {
  font-size: 70% !important;
}

.text-muted {
  color: rgba(33, 37, 41, 0.75) !important;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
}

.list-unstyled {
  list-style-type: none;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.dark-theme .bg-white {
  background-color: #14171c !important;
}
.dark-theme .bg-main-color-blue {
  background-color: #14171c !important;
}
.dark-theme .box-shadow {
  box-shadow: unset !important;
}
.dark-theme .bg-gray {
  background-color: #0f100e !important;
}
.dark-theme .gray-color {
  color: #828690 !important;
}
.dark-theme .blue-color {
  color: var(--secondaryBlueColor) !important;
}
.dark-theme .muted-color {
  color: #6a6a73 !important;
}
.dark-theme .bg-light-gray {
  background-color: #232830 !important;
}

.rtl-enabled .ms-auto {
  margin-right: auto !important;
}
.rtl-enabled .me-auto {
  margin-left: auto !important;
}
.rtl-enabled .text-start {
  text-align: right !important;
}
.rtl-enabled .text-end {
  text-align: left !important;
}
.rtl-enabled .border-top-radius {
  border-radius: 10px 10px 0 0 !important;
}
.rtl-enabled .border-bottom-radius {
  border-radius: 0 0 10px 10px !important;
}
.rtl-enabled .ps-0 {
  padding-right: 0 !important;
}
.rtl-enabled .pe-0 {
  padding-left: 0 !important;
}
.rtl-enabled .ps-5 {
  padding-right: 5px !important;
}
.rtl-enabled .ps-10 {
  padding-right: 10px !important;
}
.rtl-enabled .ps-15 {
  padding-right: 15px !important;
}
.rtl-enabled .ps-20 {
  padding-right: 20px !important;
}
.rtl-enabled .ps-25 {
  padding-right: 25px !important;
}
.rtl-enabled .ps-30 {
  padding-right: 30px !important;
}
.rtl-enabled .pe-5 {
  padding-left: 5px !important;
}
.rtl-enabled .pe-10 {
  padding-left: 10px !important;
}
.rtl-enabled .pe-15 {
  padding-left: 15px !important;
}
.rtl-enabled .pe-20 {
  padding-left: 20px !important;
}
.rtl-enabled .pe-25 {
  padding-left: 25px !important;
}
.rtl-enabled .pe-30 {
  padding-left: 30px !important;
}
.rtl-enabled .ms-0 {
  margin-right: 0 !important;
}
.rtl-enabled .ms-5 {
  margin-right: 5px !important;
}
.rtl-enabled .me-0 {
  margin-left: 0 !important;
}
.rtl-enabled .ms-15 {
  margin-right: 15px !important;
}
.rtl-enabled .ms-20 {
  margin-right: 20px !important;
}
.rtl-enabled .ms-25 {
  margin-right: 25px !important;
}
.rtl-enabled .ms-30 {
  margin-right: 30px !important;
}
.rtl-enabled .pe-10 {
  padding-left: 10px !important;
}
.rtl-enabled .pe-12 {
  padding-left: 12px !important;
}
.rtl-enabled .ps-12 {
  padding-right: 12px !important;
}
.rtl-enabled .pe-12 {
  padding-left: 12px !important;
}
.rtl-enabled .me-5 {
  margin-left: 5px !important;
}
.rtl-enabled .me-10 {
  margin-left: 10px !important;
}
.rtl-enabled .ms-10 {
  margin-right: 10px !important;
}
.rtl-enabled .me-12 {
  margin-left: 12px !important;
}
.rtl-enabled .ms-12 {
  margin-right: 12px !important;
}
.rtl-enabled .me-15 {
  margin-left: 15px !important;
}
.rtl-enabled .me-20 {
  margin-left: 20px !important;
}
.rtl-enabled .me-25 {
  margin-left: 25px !important;
}
.rtl-enabled .me-30 {
  margin-left: 30px !important;
}

/* Min width 576px */
@media (min-width: 576px) {
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-2\/5 {
    flex: 0 0 auto;
    width: 20%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}
/* Min width 768px */
@media (min-width: 768px) {
  .d-md-flex {
    display: flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-2\/5 {
    flex: 0 0 auto;
    width: 20%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .p-md-25 {
    padding: 25px !important;
  }
  .h-100vh {
    height: 100vh !important;
  }
}
/* Min width 992px */
@media (min-width: 992px) {
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-2\/5 {
    flex: 0 0 auto;
    width: 20%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}
/* Min width 1200px */
@media (min-width: 1200px) {
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-2\/5 {
    flex: 0 0 auto;
    width: 20%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .display-1 {
    font-size: 5rem;
  }
  .display-2 {
    font-size: 4.5rem;
  }
  .display-3 {
    font-size: 4rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .display-5 {
    font-size: 3rem;
  }
  .display-6 {
    font-size: 2.5rem;
  }
}
/* Min width 1400px */
@media (min-width: 1400px) {
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-2\/5 {
    flex: 0 0 auto;
    width: 20%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
}
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--heraBlueColor);
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background: #f7faff !important;
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #2db6f5;
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #ee368c;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--heraBlueColor);
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: var(--secondaryBlueColor) !important;
}

.mdc-button {
  display: inline-block !important;
  padding: 14px 35px !important;
  border-radius: 5px !important;
  min-width: unset !important;
  height: auto !important;
  min-height: unset;
  width: auto;
  font-size: var(--fontSize) !important;
  font-weight: 500 !important;
}
.mdc-button.tagus {
  --mat-mdc-button-persistent-ripple-color: var(--heraBlueColor);
}
.mdc-button.tagus.mat-unthemed {
  --mdc-text-button-label-text-color: var(--heraBlueColor);
}
.mdc-button.fw-bold {
  font-weight: 700 !important;
}
.mdc-button.fw-semibold {
  font-weight: 600 !important;
}
.mdc-button.d-block {
  text-align: center !important;
  display: block !important;
  width: 100% !important;
}
.mdc-button.small {
  padding: 11px 22px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.mdc-button .mat-mdc-button-touch-target {
  height: 100% !important;
}

.mat-mdc-outlined-button.tagus.mat-unthemed {
  --mdc-outlined-button-label-text-color: var(--heraBlueColor);
}

.mat-mdc-raised-button.tagus {
  --mdc-protected-button-container-color: var(--heraBlueColor);
}
.mat-mdc-raised-button.tagus.mat-unthemed {
  --mdc-protected-button-label-text-color: var(--whiteColor);
}
.mat-mdc-raised-button.gray {
  --mdc-protected-button-container-color: #edeff5;
}
.mat-mdc-raised-button.gray.mat-unthemed {
  --mdc-protected-button-label-text-color: var(--blackColor);
}

.mat-mdc-unelevated-button.tagus.mat-unthemed {
  --mdc-filled-button-container-color: var(--heraBlueColor);
  --mdc-filled-button-label-text-color: var(--whiteColor);
}
.mat-mdc-unelevated-button.gray.mat-unthemed {
  --mdc-filled-button-container-color: #edeff5;
  --mdc-filled-button-label-text-color: var(--blackColor);
}

.mat-mdc-icon-button.tagus {
  --mdc-icon-button-icon-color: var(--heraBlueColor);
}

.mat-mdc-fab.tagus,
.mat-mdc-mini-fab.tagus {
  --mdc-fab-container-color: var(--heraBlueColor);
  --mdc-fab-icon-color: var(--whiteColor);
  --mat-mdc-fab-color: var(--whiteColor);
}
.mat-mdc-fab.gray,
.mat-mdc-mini-fab.gray {
  --mdc-fab-container-color: #edeff5;
  --mdc-fab-icon-color: var(--blackColor);
  --mat-mdc-fab-color: var(--blackColor);
}

a.mat-mdc-icon-button:hover, a.mat-mdc-fab:hover, a.mdc-fab--mini:hover {
  color: RED;
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start,
.mat-mdc-list-option.mat-accent .mdc-list-item__end,
.mat-mdc-list-option.mat-accent .mdc-list-item__start,
.mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-selected-focus-icon-color: var(--heraBlueColor);
  --mdc-radio-selected-hover-icon-color: var(--heraBlueColor);
  --mdc-radio-selected-icon-color: var(--heraBlueColor);
  --mdc-radio-selected-pressed-icon-color: var(--heraBlueColor);
  --mat-mdc-radio-checked-ripple-color: var(--secondaryBlueColor);
  --mdc-checkbox-selected-focus-icon-color: var(--heraBlueColor);
  --mdc-checkbox-selected-hover-icon-color: var(--heraBlueColor);
  --mdc-checkbox-selected-icon-color: var(--heraBlueColor);
  --mdc-checkbox-selected-pressed-icon-color: var(--heraBlueColor);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--primaryBlueColor) !important;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--tertiaryBlueColor) !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--tertiaryBlueColor) !important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--heraBlueColor);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: var(--heraBlueColor);
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: var(--heraBlueColor);
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  width: 10px;
  height: 5px;
}

.alert {
  --alert-bg: transparent;
  --alert-padding-x: 1rem;
  --alert-padding-y: 1rem;
  --alert-margin-bottom: 1rem;
  --alert-color: inherit;
  --alert-border-color: transparent;
  --alert-border: var(--border-width) solid var(--alert-border-color);
  --alert-border-radius: 0.375rem;
  --alert-link-color: inherit;
  position: relative;
  padding: var(--alert-padding-y) var(--alert-padding-x);
  margin-bottom: var(--alert-margin-bottom);
  color: var(--alert-color);
  background-color: var(--alert-bg);
  border: var(--alert-border);
  border-radius: var(--alert-border-radius);
  --tagus-text: var(--heraBlueColor);
  --primary-text: #0a58ca;
  --secondary-text: #6c757d;
  --success-text: #146c43;
  --info-text: #087990;
  --warning-text: #997404;
  --danger-text: #b02a37;
  --light-text: #6c757d;
  --dark-text: #495057;
  --tagus-bg-subtle: rgba(117, 127, 239, 0.1);
  --primary-bg-subtle: #cfe2ff;
  --secondary-bg-subtle: #f8f9fa;
  --success-bg-subtle: #d1e7dd;
  --info-bg-subtle: #cff4fc;
  --warning-bg-subtle: #fff3cd;
  --danger-bg-subtle: #f8d7da;
  --light-bg-subtle: #fcfcfd;
  --dark-bg-subtle: #ced4da;
  --tagus-border-subtle: rgba(117, 127, 239, 0.2);
  --primary-border-subtle: #9ec5fe;
  --secondary-border-subtle: #e9ecef;
  --success-border-subtle: #a3cfbb;
  --info-border-subtle: #9eeaf9;
  --warning-border-subtle: #ffe69c;
  --danger-border-subtle: #f1aeb5;
  --light-border-subtle: #e9ecef;
  --dark-border-subtle: #adb5bd;
  --tagus-text: var(--heraBlueColor);
  --primary-text: #0a58ca;
  --secondary-text: #6c757d;
  --success-text: #146c43;
  --info-text: #087990;
  --warning-text: #997404;
  --danger-text: #b02a37;
  --light-text: #6c757d;
  --dark-text: #495057;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #dee2e6;
  --border-color-translucent: rgba(0, 0, 0, 0.175);
  --border-radius: 0.375rem;
  --border-radius-sm: 0.25rem;
  --border-radius-lg: 0.5rem;
  --border-radius-xl: 1rem;
  --border-radius-2xl: 2rem;
  --border-radius-pill: 50rem;
}
.alert i {
  font-size: 20px;
  margin-right: 5px;
}
.alert button {
  cursor: pointer;
}

.alert-tagus {
  --alert-color: var(--tagus-text);
  --alert-bg: var(--tagus-bg-subtle);
  --alert-border-color: var(--tagus-border-subtle);
  --alert-link-color: var(--tagus-text);
}

.alert-bg-tagus {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--tagus-text);
  --alert-border-color: var(--tagus-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-tagus {
  --alert-color: var(--tagus-text);
  --alert-bg: transparent;
  --alert-border-color: var(--tagus-text);
  --alert-link-color: var(--tagus-text);
}

.alert-primary {
  --alert-color: var(--primary-text);
  --alert-bg: var(--primary-bg-subtle);
  --alert-border-color: var(--primary-border-subtle);
  --alert-link-color: var(--primary-text);
}

.alert-bg-primary {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--primary-text);
  --alert-border-color: var(--primary-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-primary {
  --alert-color: var(--primary-text);
  --alert-bg: transparent;
  --alert-border-color: var(--primary-text);
  --alert-link-color: var(--primary-text);
}

.alert-secondary {
  --alert-color: var(--secondary-text);
  --alert-bg: var(--secondary-bg-subtle);
  --alert-border-color: var(--secondary-border-subtle);
  --alert-link-color: var(--secondary-text);
}

.alert-bg-secondary {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--secondary-text);
  --alert-border-color: var(--secondary-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-secondary {
  --alert-color: var(--secondary-text);
  --alert-bg: transparent;
  --alert-border-color: var(--secondary-text);
  --alert-link-color: var(--secondary-text);
}

.alert-success {
  --alert-color: var(--success-text);
  --alert-bg: var(--success-bg-subtle);
  --alert-border-color: var(--success-border-subtle);
  --alert-link-color: var(--success-text);
}

.alert-bg-success {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--success-text);
  --alert-border-color: var(--success-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-success {
  --alert-color: var(--success-text);
  --alert-bg: transparent;
  --alert-border-color: var(--success-text);
  --alert-link-color: var(--success-text);
}

.alert-danger {
  --alert-color: var(--danger-text);
  --alert-bg: var(--danger-bg-subtle);
  --alert-border-color: var(--danger-border-subtle);
  --alert-link-color: var(--danger-text);
}

.alert-bg-danger {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--danger-text);
  --alert-border-color: var(--danger-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-danger {
  --alert-color: var(--danger-text);
  --alert-bg: transparent;
  --alert-border-color: var(--danger-text);
  --alert-link-color: var(--danger-text);
}

.alert-warning {
  --alert-color: var(--warning-text);
  --alert-bg: var(--warning-bg-subtle);
  --alert-border-color: var(--warning-border-subtle);
  --alert-link-color: var(--warning-text);
}

.alert-bg-warning {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--warning-text);
  --alert-border-color: var(--warning-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-warning {
  --alert-color: var(--warning-text);
  --alert-bg: transparent;
  --alert-border-color: var(--warning-text);
  --alert-link-color: var(--warning-text);
}

.alert-info {
  --alert-color: var(--info-text);
  --alert-bg: var(--info-bg-subtle);
  --alert-border-color: var(--info-border-subtle);
  --alert-link-color: var(--info-text);
}

.alert-bg-info {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--info-text);
  --alert-border-color: var(--info-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-info {
  --alert-color: var(--info-text);
  --alert-bg: transparent;
  --alert-border-color: var(--info-text);
  --alert-link-color: var(--info-text);
}

.alert-light {
  --alert-color: var(--light-text);
  --alert-bg: var(--light-bg-subtle);
  --alert-border-color: var(--light-border-subtle);
  --alert-link-color: var(--light-text);
}

.alert-bg-light {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--light-text);
  --alert-border-color: var(--light-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-light {
  --alert-color: var(--light-text);
  --alert-bg: transparent;
  --alert-border-color: var(--light-text);
  --alert-link-color: var(--light-text);
}

.alert-dark {
  --alert-color: var(--dark-text);
  --alert-bg: var(--dark-bg-subtle);
  --alert-border-color: var(--dark-border-subtle);
  --alert-link-color: var(--dark-text);
}

.alert-bg-dark {
  --alert-color: var(--whiteColor);
  --alert-bg: var(--dark-text);
  --alert-border-color: var(--dark-text);
  --alert-link-color: var(--whiteColor);
}

.alert-outline-dark {
  --alert-color: var(--dark-text);
  --alert-bg: transparent;
  --alert-border-color: var(--dark-text);
  --alert-link-color: var(--dark-text);
}

.alert-link {
  font-weight: 700;
  color: var(--alert-link-color);
}

.avatars-list img {
  border: 2px solid var(--whiteColor);
  margin-left: -10px;
}
.avatars-list img:first-child {
  margin-left: 0;
}
.avatars-list div {
  width: 45px;
  height: 45px;
  line-height: 47px;
}

.avatars-list-with-badge div {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border: 2px solid var(--whiteColor);
  filter: drop-shadow(0px 4px 15px rgba(82, 150, 213, 0.08));
}
.avatars-list-with-badge div::before {
  top: 3px;
  width: 12px;
  right: -4px;
  height: 12px;
  content: "";
  border-radius: 50%;
  position: absolute;
  background: var(--heraBlueColor);
  border: 2px solid var(--whiteColor);
}
.avatars-list-with-badge div.off::before {
  background: red;
}
.avatars-list-with-badge div:last-child {
  margin-right: 0;
}

.badge {
  --badge-padding-x: 12px;
  --badge-padding-y: 0.4em;
  --badge-font-size: 13px;
  --badge-font-weight: 600;
  --badge-color: #fff;
  --badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--badge-padding-y) var(--badge-padding-x);
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 1;
  color: var(--badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--badge-border-radius);
}

.text-bg-tagus {
  color: #fff !important;
  background-color: var(--heraBlueColor) !important;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bg-opacity, 1)) !important;
}

.text-outline-tagus {
  color: var(--heraBlueColor) !important;
  border: 1px solid var(--heraBlueColor) !important;
}

.text-outline-primary {
  color: RGBA(13, 110, 253, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(13, 110, 253, var(--bg-opacity, 1)) !important;
}

.text-outline-secondary {
  color: RGBA(108, 117, 125, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(108, 117, 125, var(--bg-opacity, 1)) !important;
}

.text-outline-success {
  color: RGBA(25, 135, 84, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(25, 135, 84, var(--bg-opacity, 1)) !important;
}

.text-outline-danger {
  color: RGBA(220, 53, 69, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(220, 53, 69, var(--bg-opacity, 1)) !important;
}

.text-outline-warning {
  color: RGBA(255, 193, 7, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(255, 193, 7, var(--bg-opacity, 1)) !important;
}

.text-outline-info {
  color: RGBA(13, 202, 240, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(13, 202, 240, var(--bg-opacity, 1)) !important;
}

.text-outline-light {
  color: RGBA(248, 249, 250, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(248, 249, 250, var(--bg-opacity, 1)) !important;
}

.text-outline-dark {
  color: RGBA(33, 37, 41, var(--bg-opacity, 1)) !important;
  border: 1px solid RGBA(33, 37, 41, var(--bg-opacity, 1)) !important;
}

.text-soft-tagus {
  color: var(--heraBlueColor) !important;
  background-color: rgba(117, 127, 239, 0.1) !important;
}

.text-soft-primary {
  color: RGBA(13, 110, 253, var(--bg-opacity, 1)) !important;
  background-color: #cfe2ff !important;
}

.text-soft-secondary {
  color: RGBA(108, 117, 125, var(--bg-opacity, 1)) !important;
  background-color: #f8f9fa !important;
}

.text-soft-success {
  color: RGBA(25, 135, 84, var(--bg-opacity, 1)) !important;
  background-color: #d1e7dd !important;
}

.text-soft-danger {
  color: RGBA(220, 53, 69, var(--bg-opacity, 1)) !important;
  background-color: #f8d7da !important;
}

.text-soft-warning {
  color: RGBA(255, 193, 7, var(--bg-opacity, 1)) !important;
  background-color: #fff3cd !important;
}

.text-soft-info {
  color: RGBA(13, 202, 240, var(--bg-opacity, 1)) !important;
  background-color: #cff4fc !important;
}

.text-soft-light {
  color: #000000 !important;
  background-color: #fcfcfd !important;
}

.text-soft-dark {
  color: RGBA(33, 37, 41, var(--bg-opacity, 1)) !important;
  background-color: #ced4da !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus:checked ~ .mdc-checkbox__ripple {
  background-color: var(--tertiaryBlueColor) !important;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--heraBlueColor);
  --mdc-checkbox-selected-hover-icon-color: var(--heraBlueColor);
  --mdc-checkbox-selected-icon-color: var(--heraBlueColor);
  --mdc-checkbox-selected-pressed-icon-color: var(--heraBlueColor);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--tertiaryBlueColor);
}
.mat-mdc-checkbox.mat-accent.primary {
  --mdc-checkbox-selected-focus-icon-color: #0a58ca;
  --mdc-checkbox-selected-hover-icon-color: #0a58ca;
  --mdc-checkbox-selected-icon-color: #0a58ca;
  --mdc-checkbox-selected-pressed-icon-color: #0a58ca;
}
.mat-mdc-checkbox.mat-accent.primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #0a58ca;
}
.mat-mdc-checkbox.mat-accent.secondary {
  --mdc-checkbox-selected-focus-icon-color: #6c757d;
  --mdc-checkbox-selected-hover-icon-color: #6c757d;
  --mdc-checkbox-selected-icon-color: #6c757d;
  --mdc-checkbox-selected-pressed-icon-color: #6c757d;
}
.mat-mdc-checkbox.mat-accent.secondary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #6c757d;
}
.mat-mdc-checkbox.mat-accent.success {
  --mdc-checkbox-selected-focus-icon-color: #146c43;
  --mdc-checkbox-selected-hover-icon-color: #146c43;
  --mdc-checkbox-selected-icon-color: #146c43;
  --mdc-checkbox-selected-pressed-icon-color: #146c43;
}
.mat-mdc-checkbox.mat-accent.success .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #146c43;
}
.mat-mdc-checkbox.mat-accent.info {
  --mdc-checkbox-selected-focus-icon-color: #087990;
  --mdc-checkbox-selected-hover-icon-color: #087990;
  --mdc-checkbox-selected-icon-color: #087990;
  --mdc-checkbox-selected-pressed-icon-color: #087990;
}
.mat-mdc-checkbox.mat-accent.info .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #087990;
}
.mat-mdc-checkbox.mat-accent.warning {
  --mdc-checkbox-selected-focus-icon-color: #997404;
  --mdc-checkbox-selected-hover-icon-color: #997404;
  --mdc-checkbox-selected-icon-color: #997404;
  --mdc-checkbox-selected-pressed-icon-color: #997404;
}
.mat-mdc-checkbox.mat-accent.warning .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #997404;
}
.mat-mdc-checkbox.mat-accent.danger {
  --mdc-checkbox-selected-focus-icon-color: #b02a37;
  --mdc-checkbox-selected-hover-icon-color: #b02a37;
  --mdc-checkbox-selected-icon-color: #b02a37;
  --mdc-checkbox-selected-pressed-icon-color: #b02a37;
}
.mat-mdc-checkbox.mat-accent.danger .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #b02a37;
}
.mat-mdc-checkbox.mat-accent.light {
  --mdc-checkbox-selected-focus-icon-color: #6c757d;
  --mdc-checkbox-selected-hover-icon-color: #6c757d;
  --mdc-checkbox-selected-icon-color: #6c757d;
  --mdc-checkbox-selected-pressed-icon-color: #6c757d;
}
.mat-mdc-checkbox.mat-accent.light .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #6c757d;
}
.mat-mdc-checkbox.mat-accent.dark {
  --mdc-checkbox-selected-focus-icon-color: #495057;
  --mdc-checkbox-selected-hover-icon-color: #495057;
  --mdc-checkbox-selected-icon-color: #495057;
  --mdc-checkbox-selected-pressed-icon-color: #495057;
}
.mat-mdc-checkbox.mat-accent.dark .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #495057;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--secondaryBlueColor);
  --mdc-radio-selected-hover-icon-color: var(--secondaryBlueColor);
  --mdc-radio-selected-icon-color: var(--secondaryBlueColor);
  --mdc-radio-selected-pressed-icon-color: var(--secondaryBlueColor);
  --mat-mdc-radio-checked-ripple-color: var(--secondaryBlueColor);
}
.mat-mdc-radio-button.mat-accent.primary {
  --mdc-radio-selected-focus-icon-color: #0a58ca;
  --mdc-radio-selected-hover-icon-color: #0a58ca;
  --mdc-radio-selected-icon-color: #0a58ca;
  --mdc-radio-selected-pressed-icon-color: #0a58ca;
  --mat-mdc-radio-checked-ripple-color: #0a58ca;
}
.mat-mdc-radio-button.mat-accent.secondary {
  --mdc-radio-selected-focus-icon-color: #6c757d;
  --mdc-radio-selected-hover-icon-color: #6c757d;
  --mdc-radio-selected-icon-color: #6c757d;
  --mdc-radio-selected-pressed-icon-color: #6c757d;
  --mat-mdc-radio-checked-ripple-color: #6c757d;
}
.mat-mdc-radio-button.mat-accent.success {
  --mdc-radio-selected-focus-icon-color: #146c43;
  --mdc-radio-selected-hover-icon-color: #146c43;
  --mdc-radio-selected-icon-color: #146c43;
  --mdc-radio-selected-pressed-icon-color: #146c43;
  --mat-mdc-radio-checked-ripple-color: #146c43;
}
.mat-mdc-radio-button.mat-accent.info {
  --mdc-radio-selected-focus-icon-color: #087990;
  --mdc-radio-selected-hover-icon-color: #087990;
  --mdc-radio-selected-icon-color: #087990;
  --mdc-radio-selected-pressed-icon-color: #087990;
  --mat-mdc-radio-checked-ripple-color: #087990;
}
.mat-mdc-radio-button.mat-accent.warning {
  --mdc-radio-selected-focus-icon-color: #997404;
  --mdc-radio-selected-hover-icon-color: #997404;
  --mdc-radio-selected-icon-color: #997404;
  --mdc-radio-selected-pressed-icon-color: #997404;
  --mat-mdc-radio-checked-ripple-color: #997404;
}
.mat-mdc-radio-button.mat-accent.danger {
  --mdc-radio-selected-focus-icon-color: #b02a37;
  --mdc-radio-selected-hover-icon-color: #b02a37;
  --mdc-radio-selected-icon-color: #b02a37;
  --mdc-radio-selected-pressed-icon-color: #b02a37;
  --mat-mdc-radio-checked-ripple-color: #b02a37;
}
.mat-mdc-radio-button.mat-accent.light {
  --mdc-radio-selected-focus-icon-color: #6c757d;
  --mdc-radio-selected-hover-icon-color: #6c757d;
  --mdc-radio-selected-icon-color: #6c757d;
  --mdc-radio-selected-pressed-icon-color: #6c757d;
  --mat-mdc-radio-checked-ripple-color: #6c757d;
}
.mat-mdc-radio-button.mat-accent.dark {
  --mdc-radio-selected-focus-icon-color: #495057;
  --mdc-radio-selected-hover-icon-color: #495057;
  --mdc-radio-selected-icon-color: #495057;
  --mdc-radio-selected-pressed-icon-color: #495057;
  --mat-mdc-radio-checked-ripple-color: #495057;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected.tagus, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted.tagus {
  --mdc-chip-elevated-container-color: var(--heraBlueColor);
  --mdc-chip-elevated-disabled-container-color: var(--heraBlueColor);
}

.pagination {
  --pagination-padding-x: 0.75rem;
  --pagination-padding-y: 0.375rem;
  --pagination-font-size: 1rem;
  --pagination-color: var(--link-color);
  --pagination-bg: var(--body-bg);
  --pagination-border-width: var(--border-width);
  --pagination-border-color: var(--border-color);
  --pagination-border-radius: var(--border-radius);
  --pagination-hover-color: var(--link-hover-color);
  --pagination-hover-bg: var(--tertiary-bg);
  --pagination-hover-border-color: var(--border-color);
  --pagination-focus-color: var(--link-hover-color);
  --pagination-focus-bg: var(--secondary-bg);
  --pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --pagination-active-color: #fff;
  --pagination-active-bg: var(--heraBlueColor);
  --pagination-active-border-color: var(--heraBlueColor);
  --pagination-disabled-color: var(--secondary-color);
  --pagination-disabled-bg: var(--secondary-bg);
  --pagination-disabled-border-color: var(--border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
  --link-color: var(--heraBlueColor);
  --body-bg: #fff;
  --border-width: 1px;
  --border-color: #dee2e6;
  --border-radius: 0.375rem;
  --link-hover-color: var(--heraBlueColor);
  --tertiary-bg: #f8f9fa;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  font-size: var(--pagination-font-size);
  color: var(--pagination-color);
  text-decoration: none;
  background-color: var(--pagination-bg);
  border: var(--pagination-border-width) solid var(--pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.page-link:hover {
  z-index: 2;
  color: var(--pagination-hover-color);
  background-color: var(--pagination-hover-bg);
  border-color: var(--pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--pagination-focus-color);
  background-color: var(--pagination-focus-bg);
  outline: 0;
  box-shadow: var(--pagination-focus-box-shadow);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--pagination-border-radius);
  border-bottom-left-radius: var(--pagination-border-radius);
}
.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--border-width) * -1);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--pagination-border-radius);
  border-bottom-right-radius: var(--pagination-border-radius);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.05);
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--heraBlueColor);
  --mdc-switch-selected-handle-color: var(--heraBlueColor);
  --mdc-switch-selected-hover-state-layer-color: var(--heraBlueColor);
  --mdc-switch-selected-pressed-state-layer-color: var(--heraBlueColor);
  --mdc-switch-selected-focus-handle-color: var(--heraBlueColor);
  --mdc-switch-selected-hover-handle-color: var(--heraBlueColor);
  --mdc-switch-selected-pressed-handle-color: var(--heraBlueColor);
  --mdc-switch-selected-focus-track-color: rgba(117, 127, 239, 0.1);
  --mdc-switch-selected-hover-track-color: rgba(117, 127, 239, 0.1);
  --mdc-switch-selected-pressed-track-color: rgba(117, 127, 239, 0.1);
  --mdc-switch-selected-track-color: rgba(117, 127, 239, 0.1);
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: var(--heraBlueColor);
  --mdc-slider-focus-handle-color: var(--heraBlueColor);
  --mdc-slider-hover-handle-color: var(--heraBlueColor);
  --mdc-slider-active-track-color: var(--heraBlueColor);
  --mdc-slider-inactive-track-color: var(--heraBlueColor);
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: var(--heraBlueColor);
  --mat-mdc-slider-ripple-color: var(--heraBlueColor);
  --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);
}

.mat-toolbar.mat-primary {
  background: var(--heraBlueColor);
  color: white;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: var(--primaryBlueColor);
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--primaryOrangeColor);
}
.mat-mdc-tab.mat-mdc-tab :hover .mdc-tab__text-label,
.mat-mdc-tab-link.mat-mdc-tab :hover .mdc-tab__text-label {
  color: var(--primaryBlueColor);
}
.mat-mdc-tab.mat-mdc-tab :hover .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link.mat-mdc-tab :hover .mdc-tab-indicator__content--underline {
  border-color: var(--primaryOrangeColor);
}

/* Estilos para tabs activos y no activos */
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.ng-star-inserted {
  --mat-tab-header-active-ripple-color: #6ccdf773;
  --mat-tab-header-inactive-ripple-color: #6ccdf773;
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.ng-star-inserted:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: var(--primaryBlueColor);
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.ng-star-inserted:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--primaryOrangeColor);
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.ng-star-inserted:hover .mdc-tab__text-label {
  color: var(--primaryBlueColor);
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.ng-star-inserted:hover .mdc-tab-indicator__content--underline {
  border-color: #da3830;
}
.mdc-tab.mat-mdc-tab.mat-mdc-focus-indicator.ng-star-inserted .mat-mdc-tab-body-content {
  overflow: hidden;
}

.mat-mdc-tab-body-content {
  height: 100%; /* Asegura que el contenido ocupe todo el espacio disponible */
  overflow: hidden; /* Permite el scroll si el contenido excede la altura del contenedor */
  padding: 16px; /* Añade un padding si es necesario */
  box-sizing: border-box; /* Asegura que el padding se incluya dentro de las dimensiones */
}

.mat-tab-group .mat-ripple-element {
  background-color: rgba(0, 0, 255, 0.3) !important; /* Cambia este color a tu preferencia */
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--heraBlueColor);
}

.t-breadcrumb {
  --breadcrumb-padding-x: 0;
  --breadcrumb-padding-y: 0;
  --breadcrumb-margin-bottom: 1rem;
  --breadcrumb-bg: ;
  --breadcrumb-border-radius: ;
  --breadcrumb-divider-color: var(--secondary-color);
  --breadcrumb-item-padding-x: 0.5rem;
  --breadcrumb-item-active-color: var(--secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
  margin-bottom: var(--breadcrumb-margin-bottom);
  font-size: var(--breadcrumb-font-size);
  list-style: none;
  background-color: var(--breadcrumb-bg);
  border-radius: var(--breadcrumb-border-radius);
  --secondary-color: rgba(33, 37, 41, 0.75);
  --link-color: var(--heraBlueColor);
}

.t-breadcrumb-item.active {
  color: var(--breadcrumb-item-active-color);
}
.t-breadcrumb-item + .t-breadcrumb-item {
  padding-left: var(--breadcrumb-item-padding-x);
}
.t-breadcrumb-item + .t-breadcrumb-item::before {
  float: left;
  padding-right: var(--breadcrumb-item-padding-x);
  color: var(--breadcrumb-divider-color);
  content: var(--breadcrumb-divider, "/");
}
.t-breadcrumb-item a {
  color: var(--link-color);
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1rem;
}

.blockquote-footer {
  font-size: 0.875em;
  color: #6c757d;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}
.blockquote-footer::before {
  content: "— ";
}

.tagus-timeline li {
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 50px;
}
.tagus-timeline li .icon {
  left: 0;
  top: 2px;
  position: absolute;
}
.tagus-timeline li::after {
  right: 0;
  bottom: 0;
  width: 98.5%;
  content: "";
  height: 1px;
  position: absolute;
  background: #f7faff;
}
.tagus-timeline li::before {
  background: #f7faff;
  border-radius: 5px;
  position: absolute;
  height: 75.5%;
  content: "";
  width: 3px;
  left: 1px;
  top: 25px;
}
.tagus-timeline li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.tagus-timeline li:last-child::after {
  display: none;
}

.timeline {
  position: relative;
}
.timeline::before {
  top: 0;
  left: 50%;
  width: 2px;
  content: "";
  height: 100%;
  margin: 0 0 0 -1px;
  position: absolute;
  background: rgba(0, 0, 0, 0.03);
}
.timeline .event {
  margin-bottom: 25px;
  position: relative;
}
.timeline .event:after {
  display: block;
  content: "";
  clear: both;
}
.timeline .event .inner {
  width: 45%;
  float: left;
  text-align: right;
  border-radius: 5px;
}
.timeline .event .inner .date {
  background: var(--heraBlueColor);
  color: var(--whiteColor);
  margin: 0 0 0 -25px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  line-height: 46px;
  display: block;
  height: 50px;
  width: 50px;
  left: 50%;
  top: 0;
}
.timeline .event .inner .d-flex {
  justify-content: end;
}
.timeline .event:nth-child(2n+2) .inner {
  float: right;
  text-align: left;
}
.timeline .event:nth-child(2n+2) .inner .d-flex {
  justify-content: unset;
}

.tagus-basic-accordion .accordion-item {
  margin-bottom: 25px;
  display: block;
}
.tagus-basic-accordion .accordion-item .accordion-item-header {
  cursor: pointer;
  position: relative;
  color: var(--blackColor);
  padding-top: 6px;
  padding-left: 50px;
  padding-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
}
.tagus-basic-accordion .accordion-item .accordion-item-header .accordion-item-icon {
  left: 0;
  top: 50%;
  width: 36px;
  height: 36px;
  font-size: 14px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  background: #e7ebf5;
  transform: translateY(-50%);
  color: var(--heraBlueColor);
}
.tagus-basic-accordion .accordion-item .accordion-item-header .accordion-item-icon i {
  left: 0;
  right: 0;
  top: 50%;
  line-height: 1;
  margin-top: 1px;
  position: absolute;
  transform: translateY(-50%);
}
.tagus-basic-accordion .accordion-item .accordion-item-header .accordion-item-icon.close i::before {
  content: "\f126";
}
.tagus-basic-accordion .accordion-item .accordion-item-body {
  padding-top: 8px;
  padding-left: 50px;
}
.tagus-basic-accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.tagus-advance-accordion .accordion-item {
  background-color: #f5f5f9;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 20px 22px;
  display: block;
}
.tagus-advance-accordion .accordion-item .accordion-item-header {
  cursor: pointer;
  position: relative;
  color: var(--blackColor);
  font-size: 16px;
  font-weight: 600;
}
.tagus-advance-accordion .accordion-item .accordion-item-header .accordion-item-icon {
  right: 0;
  top: 50%;
  line-height: 1;
  font-size: 22px;
  margin-top: 1px;
  position: absolute;
  transform: translateY(-50%);
  color: var(--heraBlueColor);
}
.tagus-advance-accordion .accordion-item .accordion-item-header .accordion-item-icon.close i::before {
  content: "\f106";
}
.tagus-advance-accordion .accordion-item .accordion-item-body {
  margin: 0 -22px -20px;
  border-bottom: 5px solid #757fef;
  padding-top: 15px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 20px;
}
.tagus-advance-accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.tagus-basic-expansion.mat-accordion .mat-expansion-panel {
  background-color: transparent;
  color: var(--blackColor);
  margin-bottom: 23px;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel:last-child {
  margin-bottom: 0;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 0;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel:last-of-type {
  border-radius: 0;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: unset;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.tagus-basic-expansion.mat-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .tagus-basic-expansion.mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: transparent;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel.mat-expanded .mat-expansion-indicator::before {
  content: "\f126";
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-title,
.tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-description {
  color: var(--blackColor);
  margin-right: 0;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-indicator::after {
  display: none;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-indicator::before {
  left: 0;
  top: 50%;
  width: 36px;
  height: 36px;
  content: "\f125";
  line-height: 36px;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  background: #e7ebf5;
  transform: translateY(-50%);
  color: var(--tertiaryBlueColor);
  font-family: flaticon_tagus;
  font-size: 14px;
}
.tagus-basic-expansion.mat-accordion .mat-content {
  display: block;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel-header {
  height: auto;
  display: block;
  position: relative;
  padding-top: 7px;
  padding-right: 0;
  padding-left: 50px;
  padding-bottom: 7px;
}
.tagus-basic-expansion.mat-accordion .mat-expansion-panel-body {
  padding: 5px 0 0 50px;
}

.tagus-advance-expansion.mat-accordion {
  /* Webkit browsers */
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel {
  background-color: rgba(223, 237, 244, 0.3019607843) !important;
  color: var(--blackColor);
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 20px 22px;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel:last-child {
  margin-bottom: 0;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 5px;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel:last-of-type {
  border-radius: 5px;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: unset;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),
.tagus-advance-expansion.mat-accordion .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .tagus-advance-expansion.mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: transparent;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel.mat-expanded .mat-expansion-indicator::before {
  content: "\f106";
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-header-title,
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-header-description {
  color: var(--blackColor);
  margin-right: 0;
  display: block;
  font-size: 16px;
  font-weight: 600;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-indicator::after {
  display: none;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-indicator::before {
  right: 0;
  top: 50%;
  line-height: 1;
  margin-top: 1px;
  content: "\f105";
  position: absolute;
  transform: translateY(-50%);
  color: var(--tertiaryBlueColor);
  font-family: flaticon_tagus;
  font-size: 22px;
}
.tagus-advance-expansion.mat-accordion .mat-content {
  display: block;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-header {
  padding: 0;
  height: auto;
  display: block;
  position: relative;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-body {
  overflow: scroll;
  margin: 0 -22px -20px;
  border-bottom: 5px solid var(--tertiaryBlueColor);
  padding-top: 15px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 20px;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-body::-webkit-scrollbar {
  width: 5px;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #a5a5a5;
}
.tagus-advance-expansion.mat-accordion .mat-expansion-panel-body::-webkit-scrollbar-track {
  background: transparent;
}

.cdk-drag-preview {
  display: flex;
  padding: 20px;
  border-radius: 5px;
  align-items: center;
  background-color: VAR(--whiteColor);
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mat-elevation-z8 {
  box-shadow: unset;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container {
  margin-top: 10px;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
  padding: 0;
  min-height: auto;
  justify-content: space-between;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label {
  margin: 0 5px 0 0;
  color: var(--paragraphColor);
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mdc-text-field {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-select-value {
  width: auto;
  margin-right: 5px;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-select-min-line {
  color: var(--blackColor);
  font-weight: 600;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  min-height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-select-arrow {
  color: var(--paragraphColor);
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border: none !important;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-range-label {
  color: var(--paragraphColor);
  margin-left: 0;
}
.mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 6px;
}

.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: unset;
}

.mat-mdc-table {
  width: 100%;
  background: var(--whiteColor);
}
.mat-mdc-table .mdc-data-table__header-row {
  height: auto;
  background-color: #f7faff;
}
.mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
  color: var(--subtitleBlueColor);
  border-bottom: none;
  padding: 15px 20px;
  position: relative;
}
.mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:last-child::after {
  display: none;
}
.mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell::after {
  top: 50%;
  right: 0;
  width: 1px;
  content: "";
  height: 17px;
  position: absolute;
  background-color: #e7ebf5;
  transform: translateY(-50%);
}
.mat-mdc-table .mdc-data-table__row {
  height: auto;
}
.mat-mdc-table .mdc-data-table__row .mdc-data-table__cell {
  padding: 15px 20px;
  color: var(--paragraphColor);
  border-bottom-color: #eeeeee;
}
.mat-mdc-table .mdc-data-table__row .mat-mdc-footer-cell {
  border-top: 1px solid #eeeeee;
}

.dark-theme .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background: #22252b !important;
}
.dark-theme .mdc-button.tagus {
  --mat-mdc-button-persistent-ripple-color: var(--heraBlueColor);
}
.dark-theme .mdc-button.tagus.mat-unthemed {
  --mdc-text-button-label-text-color: var(--whiteColor);
}
.dark-theme .mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: #ced4da;
  --mdc-text-button-label-text-color: #ced4da;
}
.dark-theme .mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: var(--whiteColor);
}
.dark-theme .mdc-button:not(:disabled) {
  color: var(--mdc-text-button-label-text-color, inherit);
}
.dark-theme .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-outlined-button.tagus.mat-unthemed {
  --mdc-outlined-button-label-text-color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(
    255,
    255,
    255,
    0.38
  );
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #1c1c1c;
  --mdc-protected-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-raised-button.tagus {
  --mdc-protected-button-container-color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-raised-button.tagus.mat-unthemed {
  --mdc-protected-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-raised-button.gray {
  --mdc-protected-button-container-color: #0f100e;
}
.dark-theme .mat-mdc-raised-button.gray.mat-unthemed {
  --mdc-protected-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(
    255,
    255,
    255,
    0.12
  );
  --mdc-protected-button-disabled-label-text-color: rgba(
    255,
    255,
    255,
    0.38
  );
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-raised-button:not(:disabled) {
  color: var(--mdc-protected-button-label-text-color, inherit);
}
.dark-theme .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #1c1c1c;
  --mdc-filled-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-unelevated-button.tagus.mat-unthemed {
  --mdc-filled-button-container-color: var(--heraBlueColor);
  --mdc-filled-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-unelevated-button.gray.mat-unthemed {
  --mdc-filled-button-container-color: #0f100e;
  --mdc-filled-button-label-text-color: var(--whiteColor);
}
.dark-theme .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-icon-button.tagus {
  --mdc-icon-button-icon-color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-icon-button svg {
  fill: var(--whiteColor) !important;
}
.dark-theme a.mat-mdc-icon-button:hover, .dark-theme a.mat-mdc-fab:hover, .dark-theme a.mdc-fab--mini:hover {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-fab.tagus,
.dark-theme .mat-mdc-mini-fab.tagus {
  --mdc-fab-container-color: var(--heraBlueColor);
  --mdc-fab-icon-color: var(--whiteColor);
  --mat-mdc-fab-color: var(--whiteColor);
}
.dark-theme .mat-mdc-fab.gray,
.dark-theme .mat-mdc-mini-fab.gray {
  --mdc-fab-container-color: #0f100e;
  --mdc-fab-icon-color: var(--whiteColor);
  --mat-mdc-fab-color: var(--whiteColor);
}
.dark-theme .mat-mdc-fab[disabled][disabled],
.dark-theme .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.38);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(
    255,
    255,
    255,
    0.38
  );
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(
    255,
    255,
    255,
    0.38
  );
  --mdc-list-list-item-disabled-label-text-color: var(--whiteColor);
  --mdc-list-list-item-disabled-leading-icon-color: var(--whiteColor);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--whiteColor);
  --mdc-list-list-item-hover-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(255, 255, 255, 0.87);
  --mdc-list-list-item-hover-state-layer-color: var(--whiteColor);
  --mdc-list-list-item-focus-state-layer-color: var(--whiteColor);
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #181b20;
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--tertiaryBlueColor) !important;
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: var(--whiteColor);
}
.dark-theme .mdc-text-field--filled input {
  background-color: transparent;
}
.dark-theme .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--whiteColor);
}
.dark-theme .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #181b20;
}
.dark-theme .mdc-text-field--disabled .mdc-floating-label {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.dark-theme .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(255, 255, 255, 0.87);
}
.dark-theme .avatars-list img {
  border-color: #1c1c1c;
}
.dark-theme .avatars-list-with-badge div {
  border-color: #1c1c1c;
  filter: drop-shadow(0px 4px 15px rgba(82, 150, 213, 0.08));
}
.dark-theme .avatars-list-with-badge div::before {
  border-color: #1c1c1c;
}
.dark-theme .mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-focus-icon-color: var(--whiteColor);
  --mdc-checkbox-unselected-hover-icon-color: var(--whiteColor);
}
.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-unselected-focus-icon-color: var(--whiteColor);
  --mdc-checkbox-unselected-hover-icon-color: var(--whiteColor);
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(255, 255, 255, 0.87));
}
.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-unselected-focus-icon-color: var(--whiteColor);
  --mdc-checkbox-unselected-hover-icon-color: var(--whiteColor);
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #232621;
  --mdc-chip-elevated-disabled-container-color: #232621;
  --mdc-chip-label-text-color: #ced4da;
  --mdc-chip-disabled-label-text-color: #ced4da;
  --mdc-chip-with-icon-icon-color: #ced4da;
  --mdc-chip-with-icon-disabled-icon-color: #ced4da;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #ced4da;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #ced4da;
  --mdc-chip-with-icon-selected-icon-color: #ced4da;
}
.dark-theme .mat-drawer-container {
  background-color: #0f100e;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-drawer {
  background-color: #0f100e;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-drawer-side {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-mdc-paginator {
  background: #14171c;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-paginator .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #ffffff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .pagination {
  --body-bg: #1e2227;
  --border-color: #2e2e2e;
  --tertiary-bg: #000000;
}
.dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.05);
}
.dark-theme .mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #ffffff;
  --mdc-switch-disabled-unselected-handle-color: #ffffff;
  --mdc-switch-disabled-selected-track-color: #ffffff;
  --mdc-switch-disabled-unselected-track-color: #ffffff;
  --mdc-switch-unselected-focus-state-layer-color: #ffffff;
  --mdc-switch-unselected-pressed-state-layer-color: #ffffff;
  --mdc-switch-unselected-hover-state-layer-color: #ffffff;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-focus-handle-color: #6b6969;
  --mdc-switch-unselected-hover-handle-color: #6b6969;
  --mdc-switch-unselected-pressed-handle-color: #6b6969;
  --mdc-switch-unselected-focus-track-color: #414141;
  --mdc-switch-unselected-hover-track-color: #414141;
  --mdc-switch-unselected-pressed-track-color: #414141;
  --mdc-switch-unselected-track-color: #414141;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #666666);
}
.dark-theme .mat-toolbar {
  background: #1e2227;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-mdc-tab .mdc-tab__text-label,
.dark-theme .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme .mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, var(--whiteColor));
}
.dark-theme .mat-stepper-horizontal,
.dark-theme .mat-stepper-vertical {
  background-color: #1e2227;
}
.dark-theme .mat-step-header .mat-step-label {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.54);
  color: var(--blackColor);
}
.dark-theme .mat-step-header.cdk-keyboard-focused,
.dark-theme .mat-step-header.cdk-program-focused,
.dark-theme .mat-step-header:hover:not([aria-disabled]),
.dark-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-horizontal-stepper-header::before,
.dark-theme .mat-horizontal-stepper-header::after,
.dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .t-breadcrumb {
  --secondary-color: #a2a6b1;
}
.dark-theme .mat-button-toggle-appearance-standard {
  color: rgba(255, 255, 255, 0.87);
  background: #1e2227;
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme .mat-button-toggle-group-appearance-standard {
  border-color: #2e2e2e;
}
.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left-color: #2e2e2e;
}
.dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-button-toggle-checked {
  background-color: #1d2024;
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-expansion-panel {
  background: #1e2227;
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-expansion-panel-header-title {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-expansion-panel-header-description,
.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-select-value {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: rgba(255, 255, 255, 0.2);
}
.dark-theme .tagus-timeline li::after {
  background: #2e2e2e;
}
.dark-theme .tagus-timeline li::before {
  background: #2e2e2e;
}
.dark-theme .timeline::before {
  background: rgba(255, 255, 255, 0.03);
}
.dark-theme .tagus-basic-accordion .accordion-item .accordion-item-header {
  color: var(--whiteColor);
}
.dark-theme .tagus-basic-accordion .accordion-item .accordion-item-header .accordion-item-icon {
  background: #1e2227;
}
.dark-theme .tagus-advance-accordion .accordion-item {
  background-color: #1e2227;
}
.dark-theme .tagus-advance-accordion .accordion-item .accordion-item-header {
  color: var(--whiteColor);
}
.dark-theme .tagus-basic-expansion.mat-accordion .mat-expansion-panel {
  color: var(--whiteColor);
}
.dark-theme .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-title,
.dark-theme .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.dark-theme .tagus-basic-expansion.mat-accordion .mat-expansion-indicator::before {
  background: #1e2227;
}
.dark-theme .tagus-advance-expansion.mat-accordion .mat-expansion-panel {
  background-color: #1e2227;
  color: var(--whiteColor);
}
.dark-theme .tagus-advance-expansion.mat-accordion .mat-expansion-panel-header-title,
.dark-theme .tagus-advance-expansion.mat-accordion .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.dark-theme .mat-calendar-arrow {
  fill: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-calendar-table-header,
.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-calendar-body-cell-content,
.dark-theme .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.38);
}
.dark-theme .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(255, 255, 255, 0.87));
}
.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: var(--whiteColor);
  --mdc-radio-disabled-unselected-icon-color: var(--whiteColor);
  --mdc-radio-unselected-focus-icon-color: #ced4da;
  --mdc-radio-unselected-hover-icon-color: #ced4da;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: var(--whiteColor);
}
.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__start,
.dark-theme .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-unselected-focus-icon-color: #0f100e;
  --mdc-checkbox-unselected-hover-icon-color: #0f100e;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(
    255,
    255,
    255,
    0.38
  );
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-disabled-selected-icon-color: #0f100e;
  --mdc-radio-disabled-unselected-icon-color: #0f100e;
  --mdc-radio-unselected-focus-icon-color: #161616;
  --mdc-radio-unselected-hover-icon-color: #161616;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mat-mdc-radio-ripple-color: #0f100e;
}
.dark-theme .mat-tree-node,
.dark-theme .mat-nested-tree-node {
  color: rgba(255, 255, 255, 0.87);
}
.dark-theme .mat-tree {
  background: #1e2227;
}
.dark-theme .mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-page-size-label {
  color: #ced4da;
}
.dark-theme .mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-select-min-line {
  color: var(--whiteColor);
}
.dark-theme .mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-select-arrow {
  color: #ced4da;
}
.dark-theme .mat-elevation-z8 .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-paginator-range-label {
  color: #ced4da;
}
.dark-theme .mat-mdc-table {
  background: #14171c;
}
.dark-theme .mat-mdc-table .mdc-data-table__header-row {
  background-color: #1d2024;
}
.dark-theme .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell::after {
  background-color: #363636;
}
.dark-theme .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell {
  color: var(--whiteColor);
  border-bottom-color: #1f2226;
}
.dark-theme .mat-mdc-table .mdc-data-table__row .mat-mdc-footer-cell {
  border-top-color: #1f2226;
}

.rtl-enabled .mat-mdc-paginator-range-actions {
  direction: ltr;
}
.rtl-enabled .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell::after {
  right: auto;
  left: 0;
}
.rtl-enabled .t-breadcrumb-item + .t-breadcrumb-item {
  padding-left: 0;
  padding-right: var(--breadcrumb-item-padding-x);
}
.rtl-enabled .t-breadcrumb-item + .t-breadcrumb-item::before {
  float: right;
  padding-right: 0;
  padding-left: var(--breadcrumb-item-padding-x);
}
.rtl-enabled .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.rtl-enabled .mat-mdc-extended-fab > .mat-icon,
.rtl-enabled .mat-mdc-extended-fab > .material-icons {
  margin-right: -8px;
  margin-left: 12px;
}
.rtl-enabled .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
  padding-left: 12px;
  padding-right: 0;
}
.rtl-enabled .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
  padding-left: 0;
  padding-right: 12px;
}
.rtl-enabled .mat-expansion-panel-header-title,
.rtl-enabled .mat-expansion-panel-header-description {
  margin-right: 0;
  margin-left: 16px;
}
.rtl-enabled .mdc-text-field {
  padding-right: 16px;
  padding-left: 0;
}
.rtl-enabled .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-right: 16px;
  padding-left: 0;
}
.rtl-enabled .mdc-notched-outline__leading {
  border-right: 1px solid;
  border-left: none;
}
.rtl-enabled .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 0;
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-left-radius: 0;
}
.rtl-enabled .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
.rtl-enabled .mat-mdc-form-field {
  text-align: right;
}
.rtl-enabled .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid rgba(0, 0, 0, 0.38);
  border-right: 1px solid transparent;
}
.rtl-enabled .mdc-notched-outline__trailing {
  border-right: none;
  border-left: 1px solid;
}
.rtl-enabled .mdc-text-field--outlined {
  padding-right: 16px;
  padding-left: max(16px, var(--mdc-shape-small, 4px));
}
.rtl-enabled .mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  margin-right: 24px;
  margin-left: 8px;
}
.rtl-enabled .mdc-list-item--with-trailing-checkbox.mdc-list-item {
  padding-right: auto;
  padding-left: 0;
}
.rtl-enabled .mdc-list-item--with-leading-icon.mdc-list-item {
  padding-right: 0;
  padding-left: auto;
}
.rtl-enabled .mdc-list-item--with-leading-icon .mdc-list-item__start {
  margin-right: 16px;
  margin-left: 32px;
}
.rtl-enabled .mdc-list-item--with-trailing-radio .mdc-list-item__end {
  margin-right: 24px;
  margin-left: 8px;
}
.rtl-enabled .mdc-list-item--with-trailing-radio.mdc-list-item {
  padding-right: auto;
  padding-left: 0;
}
.rtl-enabled .pagination {
  padding-right: 0;
}
.rtl-enabled .page-item:first-child .page-link {
  border-top-right-radius: var(--pagination-border-radius);
  border-bottom-right-radius: var(--pagination-border-radius);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rtl-enabled .page-item:not(:first-child) .page-link {
  margin-right: calc(var(--border-width) * -1);
  margin-left: unset;
}
.rtl-enabled .page-item:last-child .page-link {
  border-top-left-radius: var(--pagination-border-radius);
  border-bottom-left-radius: var(--pagination-border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rtl-enabled .mdc-linear-progress__bar {
  transform-origin: top right;
}
.rtl-enabled .mdc-data-table__cell {
  text-align: right;
}
.rtl-enabled .tagus-timeline li {
  padding-right: 50px;
  padding-left: 0;
}
.rtl-enabled .tagus-timeline li .icon {
  left: auto;
  right: 0;
}
.rtl-enabled .tagus-timeline li::after {
  right: auto;
  left: 0;
}
.rtl-enabled .tagus-timeline li::before {
  right: 1px;
  left: auto;
}
.rtl-enabled .timeline {
  position: relative;
}
.rtl-enabled .timeline::before {
  left: auto;
  right: 50%;
  margin: 0 -1px 0 0;
}
.rtl-enabled .timeline .event .inner {
  float: right;
  text-align: left;
}
.rtl-enabled .timeline .event:nth-child(2n+2) .inner {
  float: left;
  text-align: right;
}
.rtl-enabled .tagus-basic-accordion .accordion-item .accordion-item-header {
  padding-left: 0;
  padding-right: 50px;
}
.rtl-enabled .tagus-basic-accordion .accordion-item .accordion-item-header .accordion-item-icon {
  left: auto;
  right: 0;
}
.rtl-enabled .tagus-basic-accordion .accordion-item .accordion-item-body {
  padding-left: 0;
  padding-right: 50px;
}
.rtl-enabled .tagus-advance-accordion .accordion-item .accordion-item-header .accordion-item-icon {
  right: auto;
  left: 0;
}
.rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-title,
.rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-description {
  margin-right: 0;
}
.rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-indicator::before {
  left: auto;
  right: 0;
}
.rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header {
  padding-left: 0;
  padding-right: 50px;
}
.rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-panel-body {
  padding: 5px 50px 0 0;
}
.rtl-enabled .tagus-advance-expansion.mat-accordion .mat-expansion-indicator::before {
  right: auto;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-img {
    display: none;
  }
  .mdc-button {
    padding: 12px 25px !important;
    font-size: 14px !important;
  }
  .tagus-timeline li {
    padding-left: 40px;
  }
  .timeline::before {
    left: 19px;
  }
  .timeline .event .inner {
    width: 100%;
    float: unset;
    text-align: left;
    padding-left: 55px;
  }
  .timeline .event .inner .date {
    line-height: 38px;
    height: 40px;
    width: 40px;
    margin: 0;
    left: 0;
  }
  .timeline .event .inner .d-flex {
    justify-content: unset;
  }
  .timeline .event:nth-child(2n+2) .inner {
    float: unset;
    text-align: left;
  }
  .tagus-basic-accordion .accordion-item {
    margin-bottom: 15px;
  }
  .tagus-basic-accordion .accordion-item .accordion-item-header {
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 42px;
  }
  .tagus-basic-accordion .accordion-item .accordion-item-header .accordion-item-icon {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  .tagus-basic-accordion .accordion-item .accordion-item-body {
    padding-top: 8px;
    padding-left: 42px;
  }
  .tagus-advance-accordion .accordion-item {
    padding: 15px 30px 15px 15px;
  }
  .tagus-advance-accordion .accordion-item .accordion-item-header {
    font-size: 14px;
  }
  .tagus-advance-accordion .accordion-item .accordion-item-header .accordion-item-icon {
    font-size: 16px;
    right: -20px;
  }
  .tagus-advance-accordion .accordion-item .accordion-item-body {
    margin: 0 -15px -15px;
    border-bottom-width: 3px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .tagus-basic-expansion.mat-accordion .mat-expansion-panel {
    margin-bottom: 17px;
  }
  .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-title,
  .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header-description {
    font-size: 14px;
  }
  .tagus-basic-expansion.mat-accordion .mat-expansion-indicator::before {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
  }
  .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header {
    padding-top: 0;
    padding-left: 42px;
    padding-bottom: 0;
  }
  .tagus-basic-expansion.mat-accordion .mat-expansion-panel-body {
    padding: 5px 0 0 42px;
  }
  .tagus-advance-expansion.mat-accordion .mat-expansion-panel {
    padding: 15px 30px 15px 15px;
  }
  .tagus-advance-expansion.mat-accordion .mat-expansion-panel-header-title,
  .tagus-advance-expansion.mat-accordion .mat-expansion-panel-header-description {
    font-size: 14px;
  }
  .tagus-advance-expansion.mat-accordion .mat-expansion-indicator::before {
    right: -20px;
    font-size: 16px;
  }
  .tagus-advance-expansion.mat-accordion .mat-expansion-panel-body {
    margin: 0 -15px -15px;
    border-bottom-width: 3px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
  .rtl-enabled .tagus-timeline li {
    padding-left: 0;
    padding-right: 40px;
  }
  .rtl-enabled .timeline::before {
    left: auto;
    right: 19px;
  }
  .rtl-enabled .timeline .event .inner {
    float: unset;
    text-align: right;
    padding-left: 55px;
    padding-left: 0;
    padding-right: 55px;
  }
  .rtl-enabled .timeline .event:nth-child(2n+2) .inner {
    float: unset;
    text-align: right;
  }
  .rtl-enabled .tagus-basic-accordion .accordion-item .accordion-item-header {
    padding-left: 0;
    padding-right: 42px;
  }
  .rtl-enabled .tagus-basic-accordion .accordion-item .accordion-item-body {
    padding-left: 0;
    padding-right: 42px;
  }
  .rtl-enabled .tagus-advance-accordion .accordion-item {
    padding-left: 30px;
    padding-right: 15px;
  }
  .rtl-enabled .tagus-advance-accordion .accordion-item .accordion-item-header .accordion-item-icon {
    right: auto;
    left: -20px;
  }
  .rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-panel-header {
    padding-left: 0;
    padding-right: 42px;
  }
  .rtl-enabled .tagus-basic-expansion.mat-accordion .mat-expansion-panel-body {
    padding: 5px 42px 0 0;
  }
  .rtl-enabled .tagus-advance-expansion.mat-accordion .mat-expansion-panel {
    padding-right: 15px;
    padding-left: 30px;
  }
  .rtl-enabled .tagus-advance-expansion.mat-accordion .mat-expansion-indicator::before {
    right: auto;
    left: -20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mdc-button {
    font-size: 14px !important;
  }
}
.tagus-form-group .mat-mdc-form-field {
  display: block;
}
.tagus-form-group .mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  display: none;
}
.tagus-form-group .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.tagus-form-group .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--tertiaryBlueColor) !important;
}
.tagus-form-group .mat-mdc-text-field-wrapper {
  border: 1px solid #b1b5c3;
  border-radius: 10px;
  padding: 0 15px 0 60px;
}
.tagus-form-group .date-input .mat-mdc-text-field-wrapper {
  padding: 0 15px 0 20px;
}
.tagus-form-group .date-input .mat-mdc-icon-button.mat-mdc-button-base {
  margin-right: -15px;
}
.tagus-form-group .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 12px 0 12px 0;
  margin-right: 10px;
}
.tagus-form-group .mdc-line-ripple {
  display: none;
}
.tagus-form-group .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b3b3b3;
}
.tagus-form-group i {
  top: 15px;
  left: -38px;
  line-height: 1;
  color: #b3b3b3;
  position: absolute;
  font-size: 25px;
  font-weight: normal;
}
.tagus-form-group i.ri-facebook-fill {
  color: #3b5998;
}
.tagus-form-group i.ri-twitter-fill {
  color: #00acee;
}
.tagus-form-group i.ri-linkedin-fill {
  color: #0072b1;
}
.tagus-form-group input[type=file] {
  border: 1px solid #b1b5c3;
  border-radius: 10px;
  padding: 15px 25px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.tagus-form-group label span {
  color: red;
}
.tagus-form-group.without-icon .mat-mdc-text-field-wrapper {
  padding-left: 15px;
}
.tagus-form-group .mat-mdc-checkbox {
  margin-top: -5px;
}
.tagus-form-group .mat-mdc-checkbox .mdc-checkbox {
  margin: 0 -5px 0 -10px;
}
.tagus-form-group.custom-style .mat-mdc-text-field-wrapper {
  border: none;
  border-radius: 0 0 8px 8px;
  border-bottom: 1px solid #d9d9d9;
  background-color: var(--whiteColor);
  padding-left: 0;
  padding-right: 0;
}
.tagus-form-group.custom-style .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 22px;
}
.tagus-form-group.custom-style .mdc-text-field .mdc-floating-label {
  margin-top: -5px;
}
.tagus-form-group .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 18px;
}
.tagus-form-group .mat-calendar .mat-calendar-header {
  padding: 0;
}
.tagus-form-group .mat-calendar .mat-calendar-controls {
  margin: 0 0 15px;
}
.tagus-form-group .mat-calendar .mat-calendar-period-button {
  margin: 0;
}
.tagus-form-group .mat-calendar .mdc-button {
  padding: 0px !important;
  border-radius: 0 !important;
}
.tagus-form-group .mat-calendar .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 12px;
  margin-right: 0;
}
.tagus-form-group .mat-calendar .mat-calendar-arrow {
  margin: 0 0 0 6px;
}
.tagus-form-group .mat-calendar .mat-calendar-content {
  padding: 25px 10px 10px 10px;
  background: #f4f5fa;
}
.tagus-form-group .mat-calendar .mat-calendar-table-header th {
  padding: 0 0 8px 0;
  color: var(--blackColor);
}
.tagus-form-group .mat-calendar .mat-calendar-body-cell {
  font-weight: 500;
}
.tagus-form-group .mat-calendar .mat-calendar-table-header-divider {
  display: none;
}
.tagus-form-group .mat-calendar .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--heraBlueColor) !important;
  border-color: var(--heraBlueColor) !important;
  color: var(--whiteColor) !important;
}

.tagus-search-box {
  position: relative;
}
.tagus-search-box .input-search {
  background-color: rgba(244, 244, 245, 0.6117647059);
  font-size: var(--fontSize);
  color: var(--paragraphColor);
  outline: 0 !important;
  border-radius: 30px;
  display: block;
  height: 50px;
  border: none;
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;
}
.tagus-search-box .input-search::placeholder {
  color: rgba(0, 0, 0, 0.24);
}
.tagus-search-box button {
  top: 50%;
  border: 0;
  padding: 0;
  right: 22px;
  cursor: pointer;
  margin-top: 1px;
  position: absolute;
  font-size: 22px !important;
  transform: translateY(-50%);
  color: var(--secondaryBlueColor);
  background-color: transparent;
  transition: var(--transition);
}
.tagus-search-box button.bg-color {
  right: 0;
  width: 50px;
  height: 50px;
  margin-top: 0;
  border-radius: 50%;
  color: var(--whiteColor);
  background-color: var(--heraBlueColor);
}
.tagus-search-box button.bg-color i {
  left: 0;
  right: 0;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.tagus-search-box button.bg-color:hover {
  color: var(--whiteColor);
  background-color: var(--aareRiverColor);
}
.tagus-search-box button.mdc-button {
  font-size: var(--fontSize) !important;
  border-radius: 30px !important;
  height: 50px !important;
  margin-top: 0;
  height: 50px;
  right: 0;
}

.tagus-wizard .mat-horizontal-content-container {
  padding: 0;
}
.tagus-wizard .mat-horizontal-stepper-header-container {
  margin-bottom: 20px;
}
.tagus-wizard .mat-horizontal-stepper-header {
  height: auto;
  padding: 0;
}
.tagus-wizard .mat-horizontal-stepper-header .mat-step-icon {
  background-color: #f3f3ff;
  color: var(--heraBlueColor);
  margin-right: 0;
  height: 45px;
  width: 45px;
  font-size: 20px;
  font-weight: 700;
}
.tagus-wizard .mat-step-icon-content {
  line-height: 1;
}
.tagus-wizard .mat-step-text-label {
  margin-left: 15px;
  padding-right: 15px;
  background: var(--whiteColor);
  font-size: var(--fontSize);
  font-weight: 700;
}
.tagus-wizard .mat-step-header.cdk-keyboard-focused, .tagus-wizard .mat-step-header.cdk-program-focused, .tagus-wizard .mat-step-header:hover:not([aria-disabled]), .tagus-wizard .mat-step-header:hover[aria-disabled=false] {
  background-color: transparent;
}
.tagus-wizard .mat-step-header .mat-step-icon-selected,
.tagus-wizard .mat-step-header .mat-step-icon-state-done,
.tagus-wizard .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.tagus-wizard.mat-stepper-vertical .mat-step-header .mat-step-header-ripple {
  display: none;
}
.tagus-wizard.mat-stepper-vertical .mat-step-text-label {
  margin-left: 0;
  padding-right: 0;
}
.tagus-wizard.mat-stepper-vertical .mat-vertical-stepper-header {
  height: auto;
  padding: 20px 0;
}
.tagus-wizard.mat-stepper-vertical .mat-vertical-content {
  padding: 0 0 0 25px;
}
.tagus-wizard.mat-stepper-vertical .mat-step-icon {
  background-color: #f3f3ff;
  color: var(--heraBlueColor);
  height: 45px;
  width: 45px;
  font-size: 20px;
  font-weight: 700;
}
.tagus-wizard.mat-stepper-vertical .mat-step-icon-selected,
.tagus-wizard.mat-stepper-vertical .mat-step-icon-state-done,
.tagus-wizard.mat-stepper-vertical .mat-step-icon-state-edit {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.tagus-wizard.mat-stepper-vertical .mat-stepper-vertical-line::before {
  left: -14px;
}

.tagus-form .mat-mdc-checkbox .mdc-checkbox {
  margin-left: -10px;
  margin-right: -5px;
}
.tagus-form .mat-mdc-checkbox .mdc-form-field {
  color: #a9a9c8;
}
.tagus-form .mat-mdc-checkbox .mdc-button__ripple,
.tagus-form .mat-mdc-checkbox .mat-mdc-button-persistent-ripple,
.tagus-form .mat-mdc-checkbox .mat-ripple {
  display: none;
}
.tagus-form .login-with-socials .mdc-button.gray.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before {
  display: none;
}

.tagus-form .login-with-socials .mdc-button .mdc-button__label {
  position: unset;
}

.dark-theme .tagus-form-group .mat-mdc-text-field-wrapper {
  border-color: #1f2226;
}
.dark-theme .tagus-form-group .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #a9a9c8;
}
.dark-theme .tagus-form-group i {
  color: #a9a9c8;
}
.dark-theme .tagus-form-group input[type=file] {
  border-color: #1f2226;
}
.dark-theme .tagus-form-group.custom-style .mat-mdc-text-field-wrapper {
  border-bottom-color: #1f2226;
  background-color: #14171c;
}
.dark-theme .tagus-form-group .mat-calendar .mat-calendar-content {
  background: #1e2227;
}
.dark-theme .tagus-form-group .mat-calendar .mat-calendar-table-header th {
  color: var(--whiteColor);
}
.dark-theme .tagus-search-box .input-search {
  background-color: #1e2227;
  color: var(--whiteColor);
}
.dark-theme .tagus-search-box .input-search::placeholder {
  color: #828690;
}
.dark-theme .tagus-search-box button.bg-color {
  background-color: var(--heraBlueColor) !important;
}
.dark-theme .tagus-wizard.mat-stepper-horizontal, .dark-theme .tagus-wizard.mat-stepper-vertical {
  background-color: #14171c;
}
.dark-theme .tagus-wizard .mat-horizontal-stepper-header .mat-step-icon {
  background-color: #1e2227;
}
.dark-theme .tagus-wizard .mat-step-text-label {
  background: #14171c;
}
.dark-theme .tagus-wizard.mat-stepper-vertical .mat-step-icon {
  background-color: #1e2227;
}
.dark-theme .tagus-form .mat-mdc-checkbox .mdc-checkbox {
  margin-left: -10px;
  margin-right: -5px;
}
.dark-theme .tagus-form .mat-mdc-checkbox .mdc-form-field {
  color: #a9a9c8;
}
.dark-theme .tagus-form .mat-mdc-checkbox .mdc-button__ripple,
.dark-theme .tagus-form .mat-mdc-checkbox .mat-mdc-button-persistent-ripple,
.dark-theme .tagus-form .mat-mdc-checkbox .mat-ripple {
  display: none;
}
.dark-theme .tagus-form .login-with-socials .mdc-button.gray.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before {
  display: none;
}

.rtl-enabled .tagus-form-group .mat-mdc-text-field-wrapper {
  padding-left: 15px;
  padding-right: 60px;
}
.rtl-enabled .tagus-form-group .date-input .mat-mdc-text-field-wrapper {
  padding-left: 15px;
  padding-right: 20px;
}
.rtl-enabled .tagus-form-group .date-input .mat-mdc-icon-button.mat-mdc-button-base {
  margin-right: 0;
  margin-left: -15px;
}
.rtl-enabled .tagus-form-group .mat-mdc-icon-button.mat-mdc-button-base {
  margin-right: 0;
  margin-left: 0;
}
.rtl-enabled .tagus-form-group i {
  right: -38px;
  left: auto;
}
.rtl-enabled .tagus-form-group.without-icon .mat-mdc-text-field-wrapper {
  padding-left: 0;
  padding-right: 15px;
}
.rtl-enabled .tagus-form-group .mat-mdc-checkbox .mdc-checkbox {
  margin-left: -5px;
  margin-right: -10px;
}
.rtl-enabled .tagus-form-group.custom-style .mat-mdc-text-field-wrapper {
  padding-left: 0;
  padding-right: 0;
}
.rtl-enabled .tagus-form-group .mat-calendar .mat-mdc-icon-button.mat-mdc-button-base {
  margin-left: 0;
}
.rtl-enabled .tagus-form-group .mat-calendar .mat-calendar-arrow {
  margin: 0 6px 0 0;
}
.rtl-enabled .tagus-search-box button {
  right: auto;
  left: 22px;
}
.rtl-enabled .tagus-search-box button.bg-color {
  right: auto;
  left: 0;
}
.rtl-enabled .tagus-search-box button.mdc-button {
  right: auto;
  left: 0;
}
.rtl-enabled .tagus-wizard .mat-step-text-label {
  padding-left: 15px;
  padding-right: 0;
  margin-left: 0;
  margin-right: 15px;
}
.rtl-enabled .tagus-wizard.mat-stepper-vertical .mat-step-text-label {
  margin-right: 0;
  padding-left: 0;
}
.rtl-enabled .tagus-wizard.mat-stepper-vertical .mat-vertical-content {
  padding: 0 25px 0 0;
}
.rtl-enabled .tagus-wizard.mat-stepper-vertical .mat-stepper-vertical-line::before {
  left: auto;
  right: -14px;
}
.rtl-enabled .tagus-wizard .mat-vertical-stepper-header .mat-step-icon {
  margin-right: 0;
  margin-left: 12px;
}
.rtl-enabled .tagus-form .mat-mdc-checkbox .mdc-checkbox {
  margin-right: -10px;
  margin-left: -5px;
}

.header-area .header-right-side li .profile-btn.mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.header-area .header-right-side li .profile-btn.mat-mdc-icon-button .mat-ripple {
  display: none;
}

.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 330px;
  min-width: unset;
  max-width: unset;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header {
  background-color: var(--heraBlueColor);
  border-radius: 5px 5px 0 0;
  padding: 15px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header h5 {
  color: var(--whiteColor);
  font-size: 18px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header button {
  color: var(--whiteColor);
  cursor: pointer;
  font-size: 14px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li {
  border-bottom: 1px solid #eeeeee;
  transition: var(--transition);
  padding: 15px 35px 15px 15px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li img {
  width: 50px;
  border-radius: 50%;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title {
  margin-left: 14px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title h6 {
  margin: 0 0 4px;
  font-size: 15px;
  font-weight: 600;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span {
  font-size: 13px;
  position: relative;
  padding-left: 18px;
  color: var(--paragraphColor);
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span i {
  left: 0;
  top: 50%;
  line-height: 1;
  margin-top: 0.7px;
  position: absolute;
  transform: translateY(-50%);
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .link-btn {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: block;
  position: absolute;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .close-btn {
  transition: var(--transition);
  color: var(--paragraphColor);
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
  right: 15px;
  z-index: 2;
  top: 50%;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .close-btn:hover {
  color: red;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .icon {
  position: relative;
  font-size: 30px;
  top: 2px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li:hover {
  background-color: #f8f8f8;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li:last-child {
  border-bottom: none;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item {
  width: 25%;
  flex: 0 0 auto;
  display: block;
  text-align: center;
  color: var(--paragraphColor);
  padding-top: 8px;
  padding-bottom: 8px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item span {
  margin-top: 4px;
  font-size: 14px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item:hover {
  color: var(--heraBlueColor);
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .d-flex.flex-wrap {
  padding: 7px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer {
  border-top: 1px solid #eeeeee;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 16px;
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a {
  font-size: 14px;
  padding-right: 18px;
  color: var(--heraBlueColor);
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a i {
  top: 50%;
  right: 0;
  line-height: 1;
  margin-top: 1px;
  position: absolute;
  transform: translateY(-50%);
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a:hover {
  color: var(--blackColor);
}
.menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0;
}

.profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 150px;
  min-width: unset;
  max-width: unset;
  min-height: auto;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}
.profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul {
  padding-left: 0;
  list-style-type: none;
}
.profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li a {
  display: block;
  font-size: 15px;
  position: relative;
  padding: 7px 13px 7px 37px;
  color: var(--paragraphColor);
}
.profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li a i {
  top: 50%;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li a:hover {
  background-color: #f8fafc;
}
.profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 10px 0;
}

.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 150px;
  min-width: unset;
  max-width: unset;
  min-height: auto;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}
.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul {
  padding-left: 0;
  list-style-type: none;
}
.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li {
  position: relative;
}
.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li button {
  width: 100%;
  display: block;
  cursor: pointer;
  text-align: start;
  padding: 9px 13px 9px 42px;
  color: var(--paragraphColor);
  transition: var(--transition);
  background-color: transparent;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--headingFontFamily);
}
.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li button img {
  transform: translateY(-50%);
  position: absolute;
  width: 20px;
  left: 13px;
  top: 50%;
}
.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li button:hover {
  background-color: #f8fafc;
}
.language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 10px 0;
}

.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 150px;
  min-width: unset;
  max-width: unset;
  min-height: auto;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow-y: auto;
  overflow-x: hidden;
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 10px 0;
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content button {
  display: block;
  min-height: auto;
  padding: 6px 18px;
  font-size: var(--fontSize);
  color: var(--paragraphColor);
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content button .mdc-list-item__primary-text {
  color: var(--paragraphColor);
  transition: var(--transition);
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content button:hover .mdc-list-item__primary-text {
  color: var(--heraBlueColor);
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel.dotMenu .mat-mdc-menu-content button {
  padding-left: 36px;
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel.dotMenu .mat-mdc-menu-content button i {
  top: 50%;
  left: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel.dotMenu.two {
  width: 180px;
}

.sidebar-area {
  transition: var(--transition);
  border-radius: 0 10px 10px 0;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  width: 260px;
  z-index: 222;
  left: 0;
  top: 0;
}
.sidebar-area .logo {
  position: absolute;
  display: flex;
  z-index: 2;
  right: 0;
  left: 0.8em;
  top: 1.5em;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}
.sidebar-area .logo span {
  margin-left: 15px;
}
.sidebar-area .logo a {
  color: var(--secondaryBlueColor);
  font-family: var(--headingFontFamily);
  font-size: 18px;
  font-weight: 600;
  font-line-height: 33.6px;
  font-text-align: center;
}
.sidebar-area .logo .profile-btn {
  display: flex;
  align-items: center;
}
.sidebar-area .logo .profile-btn a {
  text-align: center;
}
.sidebar-area .logo .profile-btn a img {
  width: 44px;
  height: 47px;
}
.sidebar-area .section-title {
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
}
.sidebar-area .section-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.sidebar-area .sidebar-hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.sidebar-area .sidebar-hr-header {
  border: 0;
  height: 0;
  border-top: 1px solid #6ccef7;
  border-bottom: 1px solid #6ccef7;
}
.sidebar-area .burger-menu {
  top: 30px;
  z-index: 3;
  opacity: 0;
  right: 15px;
  cursor: pointer;
  position: absolute;
  visibility: hidden;
  transition: var(--transition);
}
.sidebar-area .burger-menu span {
  height: 1px;
  width: 25px;
  margin: 6px 0;
  display: block;
  background: white;
  transition: var(--transition);
}
.sidebar-area .burger-menu.active {
  opacity: 1;
  visibility: visible;
}
.sidebar-area .burger-menu.active span.top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}
.sidebar-area .burger-menu.active span.middle-bar {
  opacity: 0;
}
.sidebar-area .burger-menu.active span.bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  margin-top: 5px;
}
.sidebar-area .sidebar-menu .sub-title {
  display: block;
  font-size: 14px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.sidebar-area .sidebar-menu .sub-title::before {
  left: 0;
  top: 50%;
  content: "";
  height: 1px;
  width: 12px;
  position: absolute;
  background: #a6acbe;
  transform: translateY(-50%);
}
.sidebar-area .sidebar-menu .sub-title:not(:first-child) {
  margin-top: 20px;
}
.sidebar-area .sidebar-inner {
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}
.sidebar-area .mat-accordion .mat-expansion-panel {
  color: white;
  background-color: transparent;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  transition: var(--transition);
  background-color: transparent;
  color: white;
  border-radius: 5px;
  position: relative;
  display: block;
  height: auto;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 14px;
  padding-right: 60px;
  padding-left: 37px;
  padding-top: 14px;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  transform: translateY(-50%);
  position: absolute;
  margin-top: 1px;
  font-size: 15px;
  line-height: 1;
  left: 15px;
  top: 50%;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content {
  display: block;
  flex-direction: unset;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  display: block;
  margin-right: 0;
  flex-grow: unset;
  flex-basis: unset;
  align-items: unset;
  color: white;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
  background-color: var(--primaryOrangeColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator {
  margin-top: -4px;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  transform: rotate(45deg);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  transform: translateY(-50%) !important;
  position: absolute;
  margin-top: -2px;
  transition: 0.2s;
  right: 15px;
  top: 50%;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  transition: 0.2s;
  transform: rotate(-45deg);
  color: var(--secondaryBlueColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
  top: 50%;
  padding: 0;
  right: 32px;
  width: 18px;
  height: 18px;
  color: #ee368c;
  line-height: 18px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  display: inline-block;
  transform: translateY(-50%);
  background: rgba(238, 54, 140, 0.1);
  font-size: 12px;
  font-weight: 600;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge.two {
  background: rgba(0, 182, 155, 0.07);
  color: #00b69b;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge.three {
  color: var(--secondaryBlueColor);
  background: rgba(117, 127, 239, 0.1);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header:hover {
  background-color: var(--primaryOrangeColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel.mat-expansion-panel-spacing {
  margin: 0;
}
.sidebar-area .mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: unset;
}
.sidebar-area .mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 0;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
  display: block;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  padding: 8px 0 3px;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu {
  padding-left: 0;
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
  display: block;
  transition: 0.3s;
  position: relative;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-left: 37px;
  padding-top: 10px;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
  top: 50%;
  left: 20px;
  width: 6px;
  height: 6px;
  content: "";
  transition: 0.3s;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  background-color: #adadb9;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover, .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active {
  background-color: var(--primaryOrangeColor);
  color: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover .badge, .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active .badge {
  background: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover::after, .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active::after {
  background-color: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link .badge {
  font-size: 11px;
  padding: 4px 8px;
  margin-left: 2px;
  border-radius: 2px;
  color: var(--primaryOrangeColor);
  transition: var(--transition);
  background: rgba(117, 127, 239, 0.1);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 10px;
  padding-left: 37px;
  padding-top: 10px;
  padding-right: 0;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
  top: 50%;
  left: 20px;
  width: 6px;
  height: 6px;
  content: "";
  transition: 0.3s;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  background-color: #adadb9;
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  color: white;
  transition: var(--transition);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover {
  background-color: var(--heraBlueColor) !important;
  color: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-content .mat-expansion-panel-header-title,
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover::after {
  background-color: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-expansion-indicator::after {
  color: var(--whiteColor);
}
.sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-body {
  padding-left: 20px;
}
.sidebar-area .mat-accordion .mat-expansion-panel:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) .mat-expansion-panel-header {
  background-color: var(--primaryOrangeColor);
}
.sidebar-area .mat-accordion .sidebar-menu-link {
  transition: var(--transition);
  color: white;
  border-radius: 5px;
  position: relative;
  display: block;
  padding-bottom: 14px;
  padding-right: 60px;
  padding-left: 37px;
  padding-top: 14px;
  font-size: 14px;
  font-weight: 600;
}
.sidebar-area .mat-accordion .sidebar-menu-link i {
  transform: translateY(-50%);
  position: absolute;
  margin-top: 1px;
  font-size: 15px;
  line-height: 1;
  left: 15px;
  top: 50%;
}
.sidebar-area .mat-accordion .sidebar-menu-link:hover, .sidebar-area .mat-accordion .sidebar-menu-link.active {
  background-color: var(--primaryOrangeColor);
}
.sidebar-area.active {
  width: 60px;
}
.sidebar-area.active .logo {
  left: 0;
  top: 1em;
}
.sidebar-area.active .logo a span {
  display: none;
}
.sidebar-area.active .logo span {
  display: none !important;
}
.sidebar-area.active .logo .profile-btn {
  display: flex;
  align-items: center;
}
.sidebar-area.active .logo .profile-btn a {
  text-align: center;
}
.sidebar-area.active .logo .profile-btn a img {
  width: 30px;
  height: auto;
}
.sidebar-area.active .sidebar-inner {
  padding-top: 80px;
  padding-left: 12px;
  padding-right: 12px;
}
.sidebar-area.active .sidebar-menu .sub-title {
  display: none;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel {
  margin-top: 3px;
  margin-bottom: 3px;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0;
  width: 36px;
  height: 36px;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: 0;
  right: 0;
  text-align: center;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
  display: none;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
  display: none;
}
.sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
  display: none;
}
.sidebar-area.active .mat-accordion .sidebar-menu-link {
  padding: 0;
  width: 36px;
  height: 36px;
}
.sidebar-area.active .mat-accordion .sidebar-menu-link i {
  left: 0;
  right: 0;
  text-align: center;
}
.sidebar-area.active .mat-accordion .sidebar-menu-link .title {
  display: none;
}
.sidebar-area.active .burger-menu {
  opacity: 0;
  visibility: hidden;
}
.sidebar-area.active:hover {
  width: 260px;
}
.sidebar-area.active:hover .logo {
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-area.active:hover .logo a span {
  display: inline-block;
}
.sidebar-area.active:hover .logo span {
  display: flex !important;
}
.sidebar-area.active:hover .logo .profile-btn {
  display: flex;
  align-items: center;
}
.sidebar-area.active:hover .logo .profile-btn a {
  text-align: center;
}
.sidebar-area.active:hover .logo .profile-btn a img {
  width: 40px;
  height: 40px;
}
.sidebar-area.active:hover .burger-menu {
  opacity: 1;
  visibility: visible;
}
.sidebar-area.active:hover .sidebar-inner {
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-area.active:hover .sidebar-menu .sub-title {
  display: block;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel {
  margin-top: 0;
  margin-bottom: 0;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  width: auto;
  height: auto;
  padding-bottom: 14px;
  padding-right: 60px;
  padding-left: 37px;
  padding-top: 14px;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: 15px;
  right: auto;
  text-align: unset;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
  display: block;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: inline-block;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
  display: inline-block;
}
.sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
  display: block;
}
.sidebar-area.active:hover .mat-accordion .sidebar-menu-link {
  width: auto;
  height: auto;
  padding-bottom: 14px;
  padding-right: 60px;
  padding-left: 37px;
  padding-top: 14px;
}
.sidebar-area.active:hover .mat-accordion .sidebar-menu-link i {
  left: 15px;
  right: auto;
  text-align: unset;
}
.sidebar-area.active:hover .mat-accordion .sidebar-menu-link .title {
  display: block;
}
.sidebar-area.hide-sidebar {
  width: 60px;
}
.sidebar-area.hide-sidebar .logo {
  padding-left: 12px;
  padding-right: 12px;
}
.sidebar-area.hide-sidebar .logo a span {
  display: none;
}
.sidebar-area.hide-sidebar .sidebar-inner {
  padding-top: 80px;
  padding-left: 12px;
  padding-right: 12px;
}
.sidebar-area.hide-sidebar .sidebar-menu .sub-title {
  display: none;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel {
  margin-top: 3px;
  margin-bottom: 3px;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0;
  width: 36px;
  height: 36px;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: 0;
  right: 0;
  text-align: center;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
  display: none;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: none;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
  display: none;
}
.sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
  display: none;
}
.sidebar-area.hide-sidebar .mat-accordion .sidebar-menu-link {
  padding: 0;
  width: 36px;
  height: 36px;
}
.sidebar-area.hide-sidebar .mat-accordion .sidebar-menu-link i {
  left: 0;
  right: 0;
  text-align: center;
}
.sidebar-area.hide-sidebar .mat-accordion .sidebar-menu-link .title {
  display: none;
}
.sidebar-area.hide-sidebar .burger-menu {
  opacity: 0;
  visibility: hidden;
}
.sidebar-area.hide-sidebar:hover {
  width: 260px;
}
.sidebar-area.hide-sidebar:hover .logo {
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-area.hide-sidebar:hover .logo a span {
  display: inline-block;
}
.sidebar-area.hide-sidebar:hover .sidebar-inner {
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-area.hide-sidebar:hover .sidebar-menu .sub-title {
  display: block;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel {
  margin-top: 0;
  margin-bottom: 0;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  width: auto;
  height: auto;
  padding-bottom: 14px;
  padding-right: 60px;
  padding-left: 37px;
  padding-top: 14px;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: 15px;
  right: auto;
  text-align: unset;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
  display: block;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: inline-block;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
  display: inline-block;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
  display: block;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link {
  width: auto;
  height: auto;
  padding-bottom: 14px;
  padding-right: 60px;
  padding-left: 37px;
  padding-top: 14px;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link i {
  left: 15px;
  right: auto;
  text-align: unset;
}
.sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link .title {
  display: block;
}
.sidebar-area.dark-sidebar {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .logo a {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .logo.bg-white,
.sidebar-area.dark-sidebar .logo .bg-main-color-blue {
  background-color: #14171c !important;
}
.sidebar-area.dark-sidebar .burger-menu span {
  background: var(--whiteColor);
}
.sidebar-area.dark-sidebar .sidebar-menu .sub-title::before {
  background: #6e6e6e;
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
  background-color: #1e2227;
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header:hover {
  background-color: #1e2227;
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
  background-color: #5c5c5c;
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover, .sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover::after, .sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active::after {
  background-color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
  background-color: #5c5c5c;
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover {
  background-color: var(--heraBlueColor) !important;
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-content .mat-expansion-panel-header-title,
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover::after {
  background-color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-expansion-indicator::after {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .mat-expansion-panel:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) .mat-expansion-panel-header {
  background-color: #1e2227;
}
.sidebar-area.dark-sidebar .mat-accordion .sidebar-menu-link {
  color: var(--whiteColor);
}
.sidebar-area.dark-sidebar .mat-accordion .sidebar-menu-link:hover, .sidebar-area.dark-sidebar .mat-accordion .sidebar-menu-link.active {
  background-color: #1e2227;
}
.sidebar-area.dark-sidebar .gray-color {
  color: #828690 !important;
}
.sidebar-area.dark-sidebar .blue-color {
  color: var(--secondaryBlueColor) !important;
}
.sidebar-area.dark-sidebar.card-borderd-theme .sidebar-area {
  box-shadow: var(--borderWhiteBoxShadow) !important;
}
.sidebar-area.right-sidebar {
  border-radius: 10px 0 0 10px;
  left: auto;
  right: 0;
}

.dark-theme .sidebar-area .logo a {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .burger-menu span {
  background: var(--whiteColor);
}
.dark-theme .sidebar-area .sidebar-menu .sub-title::before {
  background: #6e6e6e;
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded {
  background-color: #1e2227;
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header:hover {
  background-color: #1e2227;
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
  background-color: #5c5c5c;
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover, .dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link:hover::after, .dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link.active::after {
  background-color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
  background-color: #5c5c5c;
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover {
  background-color: var(--heraBlueColor) !important;
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-content .mat-expansion-panel-header-title,
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-content .mat-expansion-panel-header-description {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover::after {
  background-color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header:hover .mat-expansion-indicator::after {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .mat-expansion-panel:has(> .mat-expansion-panel-content > .mat-expansion-panel-body > .sidebar-sub-menu > .sidemenu-item > .sidemenu-link.active) .mat-expansion-panel-header {
  background-color: #1e2227;
}
.dark-theme .sidebar-area .mat-accordion .sidebar-menu-link {
  color: var(--whiteColor);
}
.dark-theme .sidebar-area .mat-accordion .sidebar-menu-link:hover, .dark-theme .sidebar-area .mat-accordion .sidebar-menu-link.active {
  background-color: #1e2227;
}
.dark-theme.card-borderd-theme .sidebar-area {
  box-shadow: var(--borderWhiteBoxShadow) !important;
}

.card-borderd-theme .sidebar-area {
  box-shadow: var(--borderBoxShadow) !important;
}

.card-borderd-theme .sidebar-area {
  box-shadow: var(--borderBoxShadow) !important;
}

.card-border-radius .sidebar-area {
  border-radius: 0 !important;
}

.rtl-enabled .sidebar-area {
  left: auto;
  right: 0;
}
.rtl-enabled .sidebar-area .logo a span {
  margin-left: 0;
  margin-right: 10px;
}
.rtl-enabled .sidebar-area .burger-menu {
  right: auto;
  left: 15px;
}
.rtl-enabled .sidebar-area .sidebar-menu .sub-title {
  padding-left: 0;
  padding-right: 20px;
}
.rtl-enabled .sidebar-area .sidebar-menu .sub-title::before {
  left: auto;
  right: 0;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding-right: 37px;
  padding-left: 60px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: auto;
  right: 15px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title,
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-description {
  margin-left: 0;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator::after {
  transform: rotate(45deg);
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  right: auto;
  left: 15px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  transform: rotate(130deg);
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
  right: auto;
  left: 32px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu {
  padding-right: 0;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
  padding-right: 37px;
  padding-left: 0;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
  left: auto;
  right: 20px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link .badge {
  margin-left: 0;
  margin-right: 2px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header {
  padding-right: 37px;
  padding-left: 0;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
  left: auto;
  right: 20px;
}
.rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-body {
  padding-left: 0;
  padding-right: 20px;
}
.rtl-enabled .sidebar-area .mat-accordion .sidebar-menu-link {
  padding-left: 60px;
  padding-right: 37px;
}
.rtl-enabled .sidebar-area .mat-accordion .sidebar-menu-link i {
  left: auto;
  right: 15px;
}
.rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel {
  margin-top: 3px;
  margin-bottom: 3px;
}
.rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0;
}
.rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: 0;
  right: 0;
}
.rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link {
  padding: 0;
}
.rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link i {
  left: 0;
  right: 0;
}
.rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel {
  margin-top: 0;
  margin-bottom: 0;
}
.rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding-bottom: 14px;
  padding-left: 60px;
  padding-right: 37px;
  padding-top: 14px;
}
.rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  right: 15px;
  left: auto;
}
.rtl-enabled .sidebar-area.active:hover .mat-accordion .sidebar-menu-link {
  padding-bottom: 14px;
  padding-left: 60px;
  padding-right: 37px;
  padding-top: 14px;
}
.rtl-enabled .sidebar-area.active:hover .mat-accordion .sidebar-menu-link i {
  right: 15px;
  left: auto;
}
.rtl-enabled .sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel {
  margin-top: 3px;
  margin-bottom: 3px;
}
.rtl-enabled .sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0;
}
.rtl-enabled .sidebar-area.hide-sidebar .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: 0;
  right: 0;
}
.rtl-enabled .sidebar-area.hide-sidebar .mat-accordion .sidebar-menu-link {
  padding: 0;
}
.rtl-enabled .sidebar-area.hide-sidebar .mat-accordion .sidebar-menu-link i {
  left: 0;
  right: 0;
}
.rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel {
  margin-top: 0;
  margin-bottom: 0;
}
.rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding-bottom: 14px;
  padding-left: 60px;
  padding-right: 37px;
  padding-top: 14px;
}
.rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
  left: auto;
  right: 15px;
}
.rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link {
  padding-bottom: 14px;
  padding-left: 60px;
  padding-right: 37px;
  padding-top: 14px;
}
.rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link i {
  left: auto;
  right: 15px;
}
.rtl-enabled .sidebar-area.right-sidebar {
  left: 0;
  right: auto;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
  .sidebar-area {
    width: 250px;
    left: -100%;
  }
  .sidebar-area .sidebar-menu-link--last {
    font-weight: 100 !important;
    line-height: 48px !important;
    text-decoration: solid underline white 1px;
  }
  .sidebar-area .sidebar-menu-link--last:hover {
    background-color: transparent !important;
  }
  .sidebar-area .logo {
    display: flex;
    position: absolute;
    background-color: var(--primaryBlueColor);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .sidebar-area .logo a {
    font-size: 1rem !important;
  }
  .sidebar-area .logo a span {
    margin-left: 15px;
  }
  .sidebar-area .sidebar-menu .sub-title {
    font-size: 13px;
  }
  .sidebar-area .burger-menu {
    top: 25px;
    right: 12px;
  }
  .sidebar-area .sidebar-inner {
    padding-top: 75px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    font-size: 13.5px;
    padding-bottom: 13px;
    padding-right: 50px;
    padding-left: 34px;
    padding-top: 13px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    font-size: 14px;
    left: 12px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header.mat-expanded .mat-expansion-indicator {
    margin-top: -2px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    margin-top: -1px;
    right: 12px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
    padding: 2px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    right: 25px;
    font-size: 11px;
    padding: 0 7px 1px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
    font-size: 13.5px;
    padding-bottom: 9px;
    padding-left: 30px;
    padding-top: 9px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
    left: 15px;
    width: 5px;
    height: 5px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header {
    font-size: 13.5px;
    padding-bottom: 9px;
    padding-left: 30px;
    padding-top: 9px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
    left: 15px;
    width: 5px;
    height: 5px;
  }
  .sidebar-area .mat-accordion .sidebar-menu-link {
    font-size: 13.5px;
    padding-bottom: 13px;
    padding-right: 50px;
    padding-left: 34px;
    padding-top: 13px;
  }
  .sidebar-area .mat-accordion .sidebar-menu-link i {
    font-size: 14px;
    left: 12px;
  }
  .sidebar-area.active {
    width: 250px;
    left: 0;
  }
  .sidebar-area.active .logo {
    padding-left: 12px;
    padding-right: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-area.active .logo a span {
    display: inline-block;
  }
  .sidebar-area.active .logo span {
    display: flex !important;
  }
  .sidebar-area.active .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active .logo .profile-btn a img {
    width: 40px;
    height: 40px;
  }
  .sidebar-area.active .logo a span {
    display: inline-block;
  }
  .sidebar-area.active .burger-menu {
    opacity: 1;
    visibility: visible;
  }
  .sidebar-area.active .sidebar-inner {
    padding-top: 80px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar-area.active .sidebar-menu .sub-title {
    display: block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    width: auto;
    height: auto;
    padding-bottom: 13px;
    padding-right: 50px;
    padding-left: 34px;
    padding-top: 13px;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 12px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
    display: block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    display: inline-block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    display: inline-block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
    display: block;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link {
    width: auto;
    height: auto;
    padding-bottom: 13px;
    padding-right: 50px;
    padding-left: 34px;
    padding-top: 13px;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link i {
    left: 12px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link .title {
    display: block;
  }
  .sidebar-area.active:hover {
    width: 250px;
  }
  .sidebar-area.active:hover .logo a span {
    display: inline-block;
  }
  .sidebar-area.active:hover .burger-menu {
    opacity: 1;
    visibility: visible;
  }
  .sidebar-area.active:hover .sidebar-inner {
    padding-top: 80px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar-area.active:hover .sidebar-menu .sub-title {
    display: block;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    width: auto;
    height: auto;
    padding-bottom: 13px;
    padding-right: 50px;
    padding-left: 34px;
    padding-top: 13px;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 12px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
    display: block;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    display: inline-block;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    display: inline-block;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
    display: block;
  }
  .sidebar-area.active:hover .mat-accordion .sidebar-menu-link {
    width: auto;
    height: auto;
    padding-bottom: 13px;
    padding-right: 50px;
    padding-left: 34px;
    padding-top: 13px;
  }
  .sidebar-area.active:hover .mat-accordion .sidebar-menu-link i {
    left: 12px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active:hover .mat-accordion .sidebar-menu-link .title {
    display: block;
  }
  .sidebar-area.right-sidebar {
    right: -100%;
    left: auto;
  }
  .sidebar-area.right-sidebar.active {
    left: auto;
    right: 0;
  }
  .rtl-enabled .sidebar-area {
    left: auto;
    right: -100%;
  }
  .rtl-enabled .sidebar-area .logo a span {
    margin-left: 0;
    margin-right: 15px;
  }
  .rtl-enabled .sidebar-area .burger-menu {
    right: auto;
    left: 12px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-left: 50px;
    padding-right: 34px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: auto;
    right: 12px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    right: auto;
    left: 12px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    right: auto;
    left: 25px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
    padding-left: 0;
    padding-right: 30px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header {
    padding-left: 0;
    padding-right: 30px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .sidebar-menu-link {
    padding-left: 50px;
    padding-right: 34px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .sidebar-menu-link i {
    left: auto;
    right: 12px;
  }
  .rtl-enabled .sidebar-area.active {
    left: auto;
    right: 0;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-bottom: 13px;
    padding-left: 50px;
    padding-right: 34px;
    padding-top: 13px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    right: 12px;
    left: auto;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link {
    padding-bottom: 13px;
    padding-left: 50px;
    padding-right: 34px;
    padding-top: 13px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link i {
    left: auto;
    right: 12px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-bottom: 13px;
    padding-left: 50px;
    padding-right: 34px;
    padding-top: 13px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    right: 12px;
    left: auto;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .sidebar-menu-link {
    padding-bottom: 13px;
    padding-left: 50px;
    padding-right: 34px;
    padding-top: 13px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .sidebar-menu-link i {
    right: 12px;
    left: auto;
  }
  .rtl-enabled .sidebar-area.right-sidebar {
    left: -100%;
    right: auto;
  }
  .rtl-enabled .sidebar-area.right-sidebar.active {
    right: auto;
    left: 0;
  }
}
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-area {
    left: -100%;
  }
  .sidebar-area .sidebar-menu-link--last {
    font-weight: 100 !important;
    line-height: 48px !important;
    text-decoration: solid underline white 1px;
  }
  .sidebar-area .sidebar-menu-link--last:hover {
    background-color: transparent !important;
  }
  .sidebar-area .sidebar-inner {
    padding-top: 85px;
  }
  .sidebar-area.active {
    width: 260px;
    left: 0;
  }
  .sidebar-area.active .logo {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-area.active .logo span {
    display: flex !important;
  }
  .sidebar-area.active .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active .logo .profile-btn a img {
    width: 40px;
    height: 40px;
  }
  .sidebar-area.active .logo a span {
    display: inline-block;
  }
  .sidebar-area.active .burger-menu {
    opacity: 1;
    visibility: visible;
  }
  .sidebar-area.active .sidebar-inner {
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-area.active .sidebar-menu .sub-title {
    display: block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    width: auto;
    height: auto;
    padding-bottom: 14px;
    padding-right: 60px;
    padding-left: 37px;
    padding-top: 14px;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 15px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
    display: block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    display: inline-block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    display: inline-block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
    display: block;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link {
    width: auto;
    height: auto;
    padding-bottom: 14px;
    padding-right: 60px;
    padding-left: 37px;
    padding-top: 14px;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link i {
    left: 15px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link .title {
    display: block;
  }
  .sidebar-area.active:hover {
    width: 260px;
  }
  .sidebar-area.active:hover .sidebar-inner {
    padding-top: 80px;
  }
  .sidebar-area.right-sidebar {
    left: auto;
    right: -100%;
  }
  .sidebar-area.right-sidebar.active {
    left: auto;
    right: 0;
  }
  .rtl-enabled .sidebar-area {
    right: -100%;
    left: auto;
  }
  .rtl-enabled .sidebar-area.active {
    left: auto;
    right: 0;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-bottom: 14px;
    padding-left: 60px;
    padding-right: 37px;
    padding-top: 14px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link {
    padding-bottom: 14px;
    padding-left: 60px;
    padding-right: 37px;
    padding-top: 14px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link i {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .sidebar-area.right-sidebar {
    right: auto;
    left: -100%;
  }
  .rtl-enabled .sidebar-area.right-sidebar.active {
    right: auto;
    left: 0;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-area {
    left: -100%;
  }
  .sidebar-area .sidebar-menu-link--last {
    font-weight: 100 !important;
    line-height: 48px !important;
    text-decoration: solid underline white 1px;
  }
  .sidebar-area .sidebar-menu-link--last:hover {
    background-color: transparent !important;
  }
  .sidebar-area.active {
    width: 260px;
    left: 0;
  }
  .sidebar-area.active .logo {
    padding-left: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-area.active .logo a span {
    display: inline-block;
  }
  .sidebar-area.active .logo span {
    display: flex !important;
  }
  .sidebar-area.active .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active .logo .profile-btn a img {
    width: 40px;
    height: 40px;
  }
  .sidebar-area.active .logo a span {
    display: inline-block;
  }
  .sidebar-area.active .burger-menu {
    opacity: 1;
    visibility: visible;
  }
  .sidebar-area.active .sidebar-inner {
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-area.active .sidebar-menu .sub-title {
    display: block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    width: auto;
    height: auto;
    padding-bottom: 14px;
    padding-right: 60px;
    padding-left: 37px;
    padding-top: 14px;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 15px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .title {
    display: block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    display: inline-block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    display: inline-block;
  }
  .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-content {
    display: block;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link {
    width: auto;
    height: auto;
    padding-bottom: 14px;
    padding-right: 60px;
    padding-left: 37px;
    padding-top: 14px;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link i {
    left: 15px;
    right: auto;
    text-align: unset;
  }
  .sidebar-area.active .mat-accordion .sidebar-menu-link .title {
    display: block;
  }
  .sidebar-area.active:hover {
    width: 260px;
  }
  .sidebar-area.active:hover .sidebar-inner {
    padding-top: 80px;
  }
  .sidebar-area.right-sidebar {
    left: auto;
    right: -100%;
  }
  .sidebar-area.right-sidebar.active {
    left: auto;
    right: 0;
  }
  .rtl-enabled .sidebar-area {
    left: auto;
    right: -100%;
  }
  .rtl-enabled .sidebar-area.active {
    left: auto;
    right: 0;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel {
    margin-top: 0;
    margin-bottom: 0;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-bottom: 14px;
    padding-left: 60px;
    padding-right: 37px;
    padding-top: 14px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link {
    padding-bottom: 14px;
    padding-left: 60px;
    padding-right: 37px;
    padding-top: 14px;
  }
  .rtl-enabled .sidebar-area.active .mat-accordion .sidebar-menu-link i {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .sidebar-area.right-sidebar {
    left: -100%;
    right: auto;
  }
  .rtl-enabled .sidebar-area.right-sidebar.active {
    left: 0;
    right: auto;
  }
}
/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .sidebar-area {
    width: 240px;
  }
  .sidebar-area .sidebar-menu-link--last {
    position: absolute !important;
    bottom: 1em;
    font-weight: 100 !important;
    line-height: 48px !important;
    text-decoration: solid underline white 1px;
  }
  .sidebar-area .sidebar-menu-link--last:hover {
    background-color: transparent !important;
  }
  .sidebar-area.active {
    width: 60px;
  }
  .sidebar-area.active .logo span {
    display: none !important;
  }
  .sidebar-area.active .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active .logo .profile-btn a img {
    width: 30px;
    height: auto;
  }
  .sidebar-area.active:hover {
    width: 240px;
  }
  .sidebar-area.active:hover .logo span {
    display: flex !important;
  }
  .sidebar-area.active:hover .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active:hover .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active:hover .logo .profile-btn a img {
    width: 40px;
    height: 40px;
  }
  .sidebar-area.hide-sidebar.active {
    width: 60px;
  }
  .sidebar-area.hide-sidebar.active:hover {
    width: 240px;
  }
}
/* Min width 1600px */
@media only screen and (min-width: 1600px) {
  .sidebar-area {
    width: 300px;
  }
  .sidebar-area .sidebar-menu-link--last {
    position: absolute !important;
    bottom: 5em;
    font-weight: 100 !important;
    line-height: 48px !important;
    text-decoration: solid underline white 1px;
  }
  .sidebar-area .sidebar-menu-link--last:hover {
    background-color: transparent !important;
  }
  .sidebar-area .logo {
    position: absolute;
    display: flex;
    z-index: 2;
    right: 0;
    left: 1.5em;
    top: 1.5em;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sidebar-area .burger-menu {
    right: 25px;
  }
  .sidebar-area .sidebar-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    font-size: var(--fontSize);
    padding-right: 75px;
    padding-left: 45px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 20px;
    font-size: 16px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    right: 20px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    right: 40px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
    font-size: 14px;
    padding-left: 45px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
    left: 26px;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header {
    font-size: 14px;
    padding-left: 45px;
    padding-right: 0;
  }
  .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
    left: 26px;
  }
  .sidebar-area .mat-accordion .sidebar-menu-link {
    padding-right: 75px;
    padding-left: 45px;
    font-size: var(--fontSize);
  }
  .sidebar-area .mat-accordion .sidebar-menu-link i {
    left: 20px;
    font-size: 16px;
  }
  .sidebar-area.active {
    width: 60px;
  }
  .sidebar-area.active .logo span {
    display: none !important;
  }
  .sidebar-area.active .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active .logo .profile-btn a img {
    width: 30px;
    height: auto;
  }
  .sidebar-area.active:hover {
    width: 300px;
  }
  .sidebar-area.active:hover .logo {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sidebar-area.active:hover .logo span {
    display: flex !important;
  }
  .sidebar-area.active:hover .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .sidebar-area.active:hover .logo .profile-btn a {
    text-align: center;
  }
  .sidebar-area.active:hover .logo .profile-btn a img {
    width: 43px;
    height: auto;
  }
  .sidebar-area.active:hover .sidebar-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-right: 75px;
    padding-left: 45px;
  }
  .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 20px;
    right: auto;
  }
  .sidebar-area.active:hover .mat-accordion .sidebar-menu-link {
    padding-right: 75px;
    padding-left: 45px;
  }
  .sidebar-area.active:hover .mat-accordion .sidebar-menu-link i {
    left: 20px;
    right: auto;
  }
  .sidebar-area.hide-sidebar {
    width: 60px;
  }
  .sidebar-area.hide-sidebar:hover {
    width: 300px;
  }
  .sidebar-area.hide-sidebar:hover .logo {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sidebar-area.hide-sidebar:hover .sidebar-inner {
    padding-left: 25px;
    padding-right: 25px;
  }
  .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-right: 75px;
    padding-left: 45px;
  }
  .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: 20px;
    right: auto;
  }
  .sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link {
    padding-right: 75px;
    padding-left: 45px;
  }
  .sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link i {
    left: 20px;
    right: auto;
  }
  .rtl-enabled .sidebar-area .burger-menu {
    right: auto;
    left: 25px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-left: 75px;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: auto;
    right: 20px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
    left: 20px;
    right: auto;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-header .badge {
    left: 40px;
    right: auto;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link {
    padding-left: 0;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .sidebar-sub-menu .sidemenu-item .sidemenu-link::after {
    left: auto;
    right: 26px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header {
    padding-left: 0;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .mat-expansion-panel .mat-expansion-panel-body .mat-expansion-panel-header::after {
    left: auto;
    right: 26px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .sidebar-menu-link {
    padding-left: 75px;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area .mat-accordion .sidebar-menu-link i {
    left: auto;
    right: 20px;
  }
  .rtl-enabled .sidebar-area.active:hover .logo span {
    display: flex !important;
  }
  .rtl-enabled .sidebar-area.active:hover .logo .profile-btn {
    display: flex;
    align-items: center;
  }
  .rtl-enabled .sidebar-area.active:hover .logo .profile-btn a {
    text-align: center;
  }
  .rtl-enabled .sidebar-area.active:hover .logo .profile-btn a img {
    width: 40px;
    height: 40px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-left: 75px;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: auto;
    right: 20px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .sidebar-menu-link {
    padding-left: 75px;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area.active:hover .mat-accordion .sidebar-menu-link i {
    right: 20px;
    left: auto;
  }
  .rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    padding-left: 75px;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .mat-expansion-panel .mat-expansion-panel-header i {
    left: auto;
    right: 20px;
  }
  .rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link {
    padding-left: 75px;
    padding-right: 45px;
  }
  .rtl-enabled .sidebar-area.hide-sidebar:hover .mat-accordion .sidebar-menu-link i {
    left: auto;
    right: 20px;
  }
}
.mat-mdc-card.tagus-card {
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
  background-color: var(--whiteColor) !important;
  border-radius: 10px !important;
  display: block !important;
  border: none !important;
  padding: 25px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header {
  padding: 0;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
  width: auto;
  cursor: pointer;
  max-width: unset;
  min-height: unset;
  max-height: unset;
  height: auto !important;
  min-width: unset !important;
  color: var(--paragraphColor);
  padding: 0 0 2px 23px !important;
  font-size: var(--fontSize) !important;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .btn::before {
  left: 0;
  top: 50%;
  content: "\f105";
  position: absolute;
  color: var(--blackColor);
  transform: translateY(-50%);
  font-family: flaticon_tagus;
  font-weight: 700;
  font-size: 14px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .btn .mdc-button__ripple,
.mat-mdc-card.tagus-card .mat-mdc-card-header .btn .mat-mdc-button-persistent-ripple,
.mat-mdc-card.tagus-card .mat-mdc-card-header .btn .mat-ripple {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn {
  top: 2px;
  width: auto;
  cursor: pointer;
  line-height: 0.01;
  max-width: unset;
  min-height: unset;
  max-height: unset;
  padding: 0 !important;
  height: auto !important;
  font-size: 25px !important;
  min-width: unset !important;
  color: var(--paragraphColor);
  transition: var(--transition);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn:hover {
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn .mdc-button__ripple,
.mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn .mat-mdc-button-persistent-ripple,
.mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn .mat-ripple {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .sub-title {
  color: var(--paragraphColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn {
  padding: 14px 25px 14px 46px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn .mdc-button__label {
  display: block;
  position: unset;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn i {
  top: 50%;
  left: 22px;
  right: auto;
  margin-top: 0;
  line-height: 1;
  font-size: 18px;
  position: absolute;
  transform: translateY(-50%);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn.small {
  padding: 11px 20px 11px 39px !important;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn.small i {
  left: 18px;
  font-size: 16px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .search-box {
  width: 280px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .search-box .input-search {
  background-color: #f5f7fa;
  padding: 0 15px 2px 15px;
  color: var(--blackColor);
  outline: 0 !important;
  border-radius: 5px;
  height: 41px;
  width: 100%;
  border: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .search-box .input-search::placeholder {
  color: #a9a9c8;
  transition: var(--transition);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .search-box .input-search:focus::placeholder {
  color: transparent;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .search-box button {
  top: 50%;
  padding: 0;
  right: 20px;
  border: none;
  line-height: 1;
  cursor: pointer;
  position: absolute;
  font-size: 22px !important;
  transform: translateY(-50%);
  color: var(--heraBlueColor);
  background-color: transparent;
  transition: var(--transition);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .search-box button:hover {
  color: var(--blackColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-header.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #eef0f7;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .mat-mdc-card-header-text {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-header .info-list .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 4px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content {
  padding: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
  text-align: center;
  font-weight: 600;
  overflow: unset;
  /* border: none; */
  padding-top: 16px;
  padding-bottom: 16px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell .mat-mdc-checkbox .mdc-checkbox {
  margin-left: -10px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #a9a9c8;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
  overflow: unset;
  padding: 15px 20px;
  text-align: center;
  white-space: nowrap;
  color: var(--paragraphColor);
  border-bottom-color: #f7faff;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
  color: var(--paragraphColor);
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info img {
  width: 48px;
  margin-right: 12px;
  border-radius: 10px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info:hover {
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
  font-size: 14px;
  border-radius: 4px;
  padding: 4px 13px 5px;
  color: var(--heraBlueColor);
  background: rgba(117, 127, 239, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.delivered {
  background: rgba(0, 182, 155, 0.1);
  color: #00b69b;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.outOfStock {
  color: #ee368c;
  background: rgba(238, 54, 140, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .mat-mdc-checkbox .mdc-checkbox {
  margin-left: -10px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #a9a9c8;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .dot-btn {
  width: 35px;
  line-height: 30px;
  padding: 0 !important;
  height: 30px !important;
  color: var(--blackColor);
  font-size: 18px !important;
  min-width: unset !important;
  border-radius: 4px !important;
  display: inline-block !important;
  background-color: #f3f6f9 !important;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .rating i {
  line-height: 1;
  color: #ffbc2b;
  margin-left: 2px;
  margin-right: 2px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span {
  width: 10px;
  height: 10px;
  font-size: 0;
  border-radius: 50%;
  display: inline-block;
  border: 1px solid #eeeeee;
  background-color: var(--whiteColor);
  margin-left: 2px;
  margin-right: 2px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.red {
  background-color: red;
  border-color: red;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.blue {
  background-color: blue;
  border-color: blue;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.green {
  background-color: green;
  border-color: green;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.orange {
  background-color: orange;
  border-color: orange;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.black {
  background-color: black;
  border-color: black;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.yellow {
  background-color: yellow;
  border-color: yellow;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.purple {
  background-color: purple;
  border-color: purple;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.brown {
  background-color: brown;
  border-color: brown;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.pink {
  background-color: pink;
  border-color: pink;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.maroon {
  background-color: maroon;
  border-color: maroon;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span.violet {
  background-color: violet;
  border-color: violet;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span:first-child {
  margin-left: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span:last-child {
  margin-right: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom: 1px solid #f7faff;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mat-sort-header-container {
  justify-content: center;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mat-sort-header-container .mat-sort-header-arrow {
  top: 2px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.recentOrdersTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
  padding-left: 25px;
  padding-right: 25px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  /* color: var(--heraBlueColor); */
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
  padding-left: 25px;
  padding-right: 25px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell a {
  color: var(--paragraphColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell a:hover {
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .mdc-checkbox {
  margin-right: 5px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info {
  display: inline-block;
  padding-left: 28px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info i,
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info img {
  left: 0;
  top: 50%;
  line-height: 1;
  font-size: 17px;
  position: absolute;
  transform: translateY(-50%);
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
  margin-right: 25px;
  color: var(--blackColor);
  font-size: 16px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info img {
  margin-right: 12px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info span {
  margin-top: 4px;
  font-size: 15px;
  font-family: var(--fontFamily);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
  background: rgba(0, 182, 155, 0.1);
  color: #00b69b;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.inactive {
  color: #ee368c;
  background: rgba(238, 54, 140, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
  padding: 12px 20px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell img {
  width: 30px;
  height: 30px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
  color: #00b69b;
  background: rgba(0, 182, 155, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.pending {
  color: #ee368c;
  background: rgba(238, 54, 140, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.completed {
  color: #757fef;
  background: rgba(117, 127, 239, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .name-info {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell:first-child {
  color: var(--blackColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info .title {
  margin-left: 12px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info .title h5 {
  font-size: 16px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info .title span {
  margin-top: 5px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
  background: rgba(0, 182, 155, 0.1);
  color: #00b69b;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.pending {
  color: #ee368c;
  background: rgba(238, 54, 140, 0.1);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar {
  -webkit-appearance: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar:vertical {
  width: 10px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar:horizontal {
  height: 10px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px solid var(--whiteColor);
  background-color: rgba(0, 0, 0, 0.2);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--whiteColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .kanban-item .dot-btn .mdc-button__ripple,
.mat-mdc-card.tagus-card .mat-mdc-card-content .kanban-item .dot-btn .mat-mdc-button-persistent-ripple,
.mat-mdc-card.tagus-card .mat-mdc-card-content .kanban-item .dot-btn .mat-ripple {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content:last-child {
  padding-bottom: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content:first-child {
  padding-top: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group {
  display: block;
  margin-top: 25px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
  margin-bottom: 20px;
  border-bottom: 1px solid #f7faff;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
  height: auto;
  display: block;
  min-width: auto;
  margin-right: 15px;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: #f5f7fa;
  transition: var(--transition);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
  transition: var(--transition);
  color: var(--blackColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab-indicator {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:last-child {
  margin-right: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--whiteColor);
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body {
  overflow: hidden;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content {
  overflow: hidden;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-form-field {
  display: block;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--tertiaryBlueColor) !important;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-text-field-wrapper {
  border-radius: 10px;
  padding: 0 15px 0 60px;
  border: 1px solid #e7ebf5;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .date-input .mat-mdc-text-field-wrapper {
  padding: 0 15px 0 20px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 12px 0 12px 0;
  margin-right: -15px;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mdc-line-ripple {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b3b3b3;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box i {
  top: 15.5px;
  left: -38px;
  line-height: 1;
  color: #262d48;
  position: absolute;
  font-size: 25px;
  font-weight: normal;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box input[type=file] {
  border: 1px solid #b1b5c3;
  border-radius: 10px;
  padding: 15px 25px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .default-btn {
  width: auto;
  max-width: unset;
  min-height: unset;
  max-height: unset;
  height: auto !important;
  color: var(--whiteColor);
  min-width: unset !important;
  padding: 17px 50px !important;
  background-color: var(--heraBlueColor);
  font-family: var(--headingFontFamily) !important;
  font-weight: 600 !important;
  font-size: 17px !important;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .default-btn .mdc-button__label {
  display: block;
  position: unset;
}
.mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .default-btn:hover {
  color: var(--whiteColor);
  background-color: var(--aareRiverColor);
}
.mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
}
.mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  padding: 8px 10px;
  border-bottom: none;
  margin-bottom: 3px;
  background: #f7faff;
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text {
  color: var(--paragraphColor);
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text .apexcharts-tooltip-text-y-value {
  color: var(--blackColor);
}
.mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-marker {
  top: 0.5px;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.mat-mdc-card.tagus-card .mat-mdc-checkbox.mat-accent .mat-mdc-checkbox-ripple {
  display: none;
}
.mat-mdc-card.tagus-card .mat-mdc-checkbox.mat-accent .mdc-form-field {
  color: var(--paragraphColor);
}
.mat-mdc-card.tagus-card::after {
  display: none;
}
.mat-mdc-card.tagus-card.bg-main-color {
  background-color: var(--heraBlueColor) !important;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header {
  margin-bottom: 15px;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
  height: auto;
  display: block;
  min-width: auto;
  margin-right: 15px;
  padding: 16px 35px;
  border-radius: 5px;
  background-color: #f5f7fa;
  transition: var(--transition);
  font-size: 18px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
  transition: var(--transition);
  color: var(--blackColor);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab-indicator {
  display: none;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:last-child {
  margin-right: 0;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--whiteColor);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body {
  overflow: hidden;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content {
  overflow: hidden;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  text-align: start;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:nth-child(2) {
  text-align: center;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
  margin-right: -45px;
  text-align: start;
  font-size: 16px;
  font-weight: 600;
  font-family: var(--headingFontFamily);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info img {
  width: 75px;
  margin-right: 18px;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info a {
  max-width: 275px;
  line-height: 1.6;
  white-space: normal;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell span {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 14px;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell span:last-child {
  margin-bottom: 0;
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
  border-radius: 4px;
  padding: 4px 20px 5px;
  color: var(--heraBlueColor);
  background: rgba(117, 127, 239, 0.1);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.failed {
  color: #ee368c;
  background: rgba(238, 54, 140, 0.1);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge.passed {
  color: #00b69b;
  background: rgba(0, 182, 155, 0.1);
}
.mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell:nth-child(2) {
  text-align: center;
}
.mat-mdc-card.tagus-card.create-project-card form .mat-mdc-form-field {
  display: block;
}
.mat-mdc-card.tagus-card.create-project-card form .mat-mdc-form-field .mat-mdc-form-field-focus-overlay {
  display: none;
}
.mat-mdc-card.tagus-card.create-project-card form .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mat-mdc-card.tagus-card.create-project-card form .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--tertiaryBlueColor) !important;
}
.mat-mdc-card.tagus-card.create-project-card form .mat-mdc-text-field-wrapper {
  border: 1px solid #b1b5c3;
  border-radius: 10px;
  padding: 0 15px 0 60px;
}
.mat-mdc-card.tagus-card.create-project-card form .date-input .mat-mdc-text-field-wrapper {
  padding: 0 15px 0 20px;
}
.mat-mdc-card.tagus-card.create-project-card form .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 12px 0 12px 0;
  margin-right: -15px;
}
.mat-mdc-card.tagus-card.create-project-card form .mdc-line-ripple {
  display: none;
}
.mat-mdc-card.tagus-card.create-project-card form .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #a9a9c8;
}
.mat-mdc-card.tagus-card.create-project-card form i {
  top: 15.5px;
  left: -38px;
  line-height: 1;
  color: #a9a9c8;
  position: absolute;
  font-size: 25px;
  font-weight: normal;
}
.mat-mdc-card.tagus-card.create-project-card form input[type=file] {
  border: 1px solid #b1b5c3;
  border-radius: 10px;
  padding: 15px 25px;
  cursor: pointer;
  display: block;
  width: 100%;
}
.mat-mdc-card.tagus-card.create-project-card .default-btn {
  width: auto;
  max-width: unset;
  min-height: unset;
  max-height: unset;
  height: auto !important;
  color: var(--whiteColor);
  min-width: unset !important;
  padding: 17px 50px !important;
  background-color: var(--heraBlueColor);
  font-family: var(--headingFontFamily) !important;
  font-weight: 600 !important;
  font-size: 17px !important;
}
.mat-mdc-card.tagus-card.create-project-card .default-btn .mdc-button__label {
  display: block;
  position: unset;
}
.mat-mdc-card.tagus-card.create-project-card .default-btn:hover {
  color: var(--whiteColor);
  background-color: var(--aareRiverColor);
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel {
  background: #f7faff;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: unset;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel:last-child {
  margin-bottom: 0;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header {
  height: auto;
  display: block;
  padding: 20px 25px;
  position: relative;
  font-size: 16px;
  font-weight: 600;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header .mat-content {
  display: block;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-indicator {
  position: absolute;
  right: 25px;
  top: 16px;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-indicator::after {
  padding: 4px;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header-title,
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header-description {
  margin-right: 0;
  display: block;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header-title {
  color: var(--blackColor);
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body {
  padding: 5px 25px 25px;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list {
  list-style: none;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li {
  border-bottom: 1px solid #e7ebf5;
  padding-top: 15px;
  padding-bottom: 15px;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li i {
  transform: translateY(-50%);
  color: var(--heraBlueColor);
  position: absolute;
  font-size: 20px;
  top: 50%;
  left: 0;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li span:nth-child(1) {
  padding-left: 25px;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li:first-child {
  padding-top: 0;
}
.mat-mdc-card.tagus-card .mat-mdc-row,
.mat-mdc-card.tagus-card .mdc-data-table__content {
  font-family: var(--fontFamily);
}

.chart .apexcharts-datalabel {
  color: var(--blackColor);
  font-family: var(--fontFamily) !important;
  font-size: 14px;
}
.chart .apexcharts-text {
  color: var(--paragraphColor);
  font-family: var(--fontFamily) !important;
  font-size: 14px;
}
.chart .apexcharts-title-text {
  font-family: var(--fontFamily) !important;
}
.chart .apexcharts-legend-text {
  font-family: var(--fontFamily) !important;
}
.chart .apexcharts-legend-marker {
  margin-right: 5px;
}
.chart .apexcharts-legend-series {
  margin: 0 10px !important;
}
.chart .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
}
.chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  padding: 8px 10px;
  border-bottom: none;
  margin-bottom: 3px;
  background: #f7faff;
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text {
  color: var(--paragraphColor);
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text .apexcharts-tooltip-text-y-value {
  color: var(--blackColor);
}
.chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-marker {
  top: 0.5px;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.chart .apexcharts-xaxistooltip,
.chart .apexcharts-yaxistooltip {
  border: 0;
  background-color: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
}
.chart .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text,
.chart .apexcharts-yaxistooltip .apexcharts-xaxistooltip-text {
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.chart .apexcharts-xaxistooltip::before, .chart .apexcharts-xaxistooltip::after,
.chart .apexcharts-yaxistooltip::before,
.chart .apexcharts-yaxistooltip::after {
  display: none;
}
.chart .apexcharts-tooltip-text {
  font-family: var(--fontFamily) !important;
}
.chart.visitsByDayChart {
  margin-bottom: -25px !important;
}
.chart.liveVisitsChart {
  padding-bottom: 5px;
}
.chart.liveVisitsChart .apexcharts-text {
  font-size: 13px;
}
.chart.liveVisitsChart .apexcharts-tooltip .apexcharts-tooltip-text {
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.chart.liveVisitsChart .apexcharts-datalabel,
.chart.liveVisitsChart .apexcharts-datalabel-label,
.chart.liveVisitsChart .apexcharts-datalabel-value,
.chart.liveVisitsChart .apexcharts-datalabels,
.chart.liveVisitsChart .apexcharts-pie-label {
  font-family: var(--fontFamily) !important;
}
.chart.yourProgressChart {
  margin-bottom: -10px !important;
}
.chart.hoursSpentChart {
  margin-bottom: -20px !important;
}
.chart.salesAnalyticsChart {
  margin-bottom: -25px !important;
}
.chart.tasksPerformanceChart .apexcharts-legend-marker {
  top: 1px !important;
}
.chart.tasksPerformanceChart .apexcharts-legend-series {
  margin: 0 10px 8px !important;
}
.chart.marketGraphChart {
  margin-bottom: -10px !important;
}
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light {
  box-shadow: 0px 10px 35px rgba(50, 110, 189, 0.2);
  border-radius: 10px;
}
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-custom-tooltip,
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-box {
  padding: 15px 18px;
}
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-custom-tooltip > div,
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-box > div {
  margin: 0 0 11px;
  font-weight: 600;
  color: var(--paragraphColor);
}
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-custom-tooltip > div .value,
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-box > div .value {
  font-weight: normal;
}
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-custom-tooltip > div:last-child,
.chart.marketGraphChart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-box > div:last-child {
  margin-bottom: 0;
}
.chart.marketGraphChart .apexcharts-ycrosshairs-hidden,
.chart.marketGraphChart .apexcharts-ycrosshairs {
  stroke: transparent;
  display: none;
}
.chart.ticketsStatusChart {
  margin-bottom: -30px !important;
  margin-top: -10px !important;
}
.chart.revenueSummaryChart {
  margin-bottom: -30px !important;
  margin-top: -5px !important;
}
.chart.revenueSummaryChart .apexcharts-datalabel {
  font-size: 12px;
}

.NgxEditor__Wrapper {
  border: 1px solid #b1b5c3 !important;
  border-radius: 10px !important;
}
.NgxEditor__Wrapper .NgxEditor__MenuBar {
  border-color: #b1b5c3 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.NgxEditor__Wrapper .NgxEditor {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.NgxEditor__Wrapper .NgxEditor__Content {
  padding: 15px !important;
}

quill-editor {
  width: 100%;
}
quill-editor .ql-toolbar.ql-snow {
  border-color: #b1b5c3;
  border-radius: 10px 10px 0 0;
  font-family: var(--fontFamily);
}
quill-editor .ql-container {
  font-family: var(--fontFamily);
  font-size: var(--fontSize);
}
quill-editor .ql-container.ql-snow {
  border-color: #b1b5c3;
  border-radius: 0 0 10px 10px;
}
quill-editor .ql-snow.ql-toolbar button:hover,
quill-editor .ql-snow .ql-toolbar button:hover,
quill-editor .ql-snow.ql-toolbar button:focus,
quill-editor .ql-snow .ql-toolbar button:focus,
quill-editor .ql-snow.ql-toolbar button.ql-active,
quill-editor .ql-snow .ql-toolbar button.ql-active,
quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover,
quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover,
quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active,
quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active,
quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover,
quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover,
quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--heraBlueColor);
}
quill-editor .ql-snow.ql-toolbar button:hover .ql-stroke,
quill-editor .ql-snow .ql-toolbar button:hover .ql-stroke,
quill-editor .ql-snow.ql-toolbar button:focus .ql-stroke,
quill-editor .ql-snow .ql-toolbar button:focus .ql-stroke,
quill-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke,
quill-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke,
quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
quill-editor .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
quill-editor .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
quill-editor .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
quill-editor .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
quill-editor .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
quill-editor .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
quill-editor .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
quill-editor .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--heraBlueColor);
}

.breadcrumb-card .breadcrumb {
  list-style-type: "circle";
  color: var(--subtitleBlueColor);
  margin-top: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.breadcrumb-card .breadcrumb .breadcrumb-item {
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-family: var(--headingFontFamily);
  margin-left: 1px;
  margin-right: 10px;
}
.breadcrumb-card .breadcrumb .breadcrumb-item::before {
  top: 50%;
  width: 30px;
  height: 5px;
  left: -13px;
  content: "/";
  color: #6ccef7;
  margin-right: 10px;
  /* position: absolute; */
  /*                 border-radius: 50%; */
  /* background: #A0A0A0; */
  transform: translateY(-50%);
}
.breadcrumb-card .breadcrumb .breadcrumb-item a {
  color: var(--primaryBlueColor);
}
.breadcrumb-card .breadcrumb .breadcrumb-item a:hover {
  color: var(--primaryOrangeColor);
}
.breadcrumb-card .breadcrumb .breadcrumb-item:first-child {
  display: flex;
  align-items: center;
  margin-left: 0;
}
.breadcrumb-card .breadcrumb .breadcrumb-item:first-child::before {
  display: none;
}
.breadcrumb-card .breadcrumb .breadcrumb-item:first-child a {
  position: relative;
}
.breadcrumb-card .breadcrumb .breadcrumb-item:first-child a i {
  left: 0;
  top: 50%;
  font-size: 16px;
  position: absolute;
  transform: translateY(-50%);
}
.breadcrumb-card .breadcrumb .breadcrumb-item:last-child {
  font-weight: normal;
  margin-right: 0;
}

.create-dialog-box .title {
  background: #f7f8f7;
  padding: 25px;
}
.create-dialog-box .title .close-btn {
  transition: var(--transition);
  font-size: 18px !important;
  cursor: pointer;
  line-height: 1;
}
.create-dialog-box .title .close-btn:hover {
  color: var(--heraBlueColor);
}
.create-dialog-box form {
  padding: 25px;
}
.create-dialog-box form .form-group {
  margin-bottom: 25px;
}
.create-dialog-box form .form-group label {
  color: var(--blackColor);
  margin-bottom: 10px;
}
.create-dialog-box form .form-group .input-control {
  outline: 0;
  width: 100%;
  height: 55px;
  display: block;
  box-shadow: unset;
  border-radius: 10px;
  color: var(--blackColor);
  font-size: var(--fontSize);
  border: 1px solid #b1b5c3;
  transition: var(--transition);
  padding-left: 20px;
  padding-right: 20px;
}
.create-dialog-box form .form-group .input-control::placeholder {
  color: #a9a9c8;
  transition: var(--transition);
}
.create-dialog-box form .form-group .input-control:focus {
  border-color: var(--heraBlueColor);
}
.create-dialog-box form .form-group .input-control:focus::placeholder {
  color: transparent;
}
.create-dialog-box form .form-group .input-control[type=file] {
  line-height: 55px;
}
.create-dialog-box form .form-group .input-folder-name {
  outline: 0;
  width: 100%;
  height: 55px;
  display: block;
  box-shadow: unset;
  border-radius: 10px;
  color: var(--blackColor);
  font-size: var(--fontSize);
  border: 1px solid #b1b5c3;
  transition: var(--transition);
  padding-left: 20px;
  padding-right: 20px;
}
.create-dialog-box form .form-group .input-folder-name::placeholder {
  color: #a9a9c8;
  transition: var(--transition);
}
.create-dialog-box form .form-group .input-folder-name:focus {
  border-color: var(--heraBlueColor);
}
.create-dialog-box form .form-group .input-folder-name:focus::placeholder {
  color: transparent;
}
.create-dialog-box form button {
  background-color: var(--heraBlueColor);
  transition: var(--transition);
  padding: 15px 28px 15px 50px;
  color: var(--whiteColor);
  display: inline-block;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  font-weight: 600;
  font-size: 14px !important;
}
.create-dialog-box form button i {
  top: 50%;
  left: 25px;
  line-height: 1;
  font-size: 18px;
  position: absolute;
  transform: translateY(-50%);
}
.create-dialog-box form button:hover {
  color: var(--whiteColor);
  background-color: var(--aareRiverColor);
}
.create-dialog-box form button.gray {
  background-color: #edeff5;
  color: var(--blackColor);
  margin-right: 15px;
}
.create-dialog-box form button.gray:hover {
  background-color: #edeff5;
  color: var(--blackColor);
}
.create-dialog-box iframe {
  height: 338px;
  width: 100%;
  border: 0;
}

.fc .fc-header-toolbar .fc-button {
  box-shadow: unset !important;
  text-transform: capitalize;
}
.fc .fc-scrollgrid {
  border-color: #edeff5 !important;
}
.fc .fc-daygrid-day-number {
  font-weight: 600;
  color: var(--blackColor);
}
.fc .fc-daygrid-day.fc-day-today {
  background: #f7faff !important;
}
.fc .fc-h-event {
  border-radius: 0;
  margin: 0 !important;
  border-color: transparent;
  background-color: transparent;
}
.fc .fc-h-event .fc-event-main {
  color: var(--blackColor);
}
.fc .fc-daygrid-block-event .fc-event-time,
.fc .fc-daygrid-block-event .fc-event-title {
  padding: 0 15px;
  white-space: normal;
  color: var(--heraBlueColor);
  font-size: 15px;
  font-weight: 600;
}
.fc td:nth-child(2) .fc-daygrid-block-event .fc-event-time,
.fc td:nth-child(2) .fc-daygrid-block-event .fc-event-title {
  color: #00b69b;
}
.fc td:nth-child(3) .fc-daygrid-block-event .fc-event-time,
.fc td:nth-child(3) .fc-daygrid-block-event .fc-event-title {
  color: #6200ee;
}
.fc td:nth-child(4) .fc-daygrid-block-event .fc-event-time,
.fc td:nth-child(4) .fc-daygrid-block-event .fc-event-title {
  color: #ffbc2b;
}
.fc td:nth-child(5) .fc-daygrid-block-event .fc-event-time,
.fc td:nth-child(5) .fc-daygrid-block-event .fc-event-title {
  color: #ff5e6f;
}
.fc td:nth-child(7) .fc-daygrid-block-event .fc-event-time,
.fc td:nth-child(7) .fc-daygrid-block-event .fc-event-title {
  color: #02a0fc;
}
.fc.fc-theme-standard th .fc-scrollgrid-sync-inner {
  padding-top: 12px;
  padding-bottom: 12px;
}
.fc.fc-theme-standard td,
.fc.fc-theme-standard th {
  border-color: #edeff5;
}

.owl-theme .owl-nav {
  margin-top: 0 !important;
}
.owl-theme .owl-nav [class*=owl-] {
  top: 50%;
  margin: 0;
  left: 15px;
  padding: 0;
  border: none;
  line-height: 1;
  font-size: 28px;
  border-radius: 0;
  position: absolute;
  color: var(--heraBlueColor);
  transform: translateY(-50%);
  transition: var(--transition);
  background-color: transparent;
}
.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 15px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
  color: var(--aareRiverColor);
}
.owl-theme .owl-dots {
  margin-top: 15px;
}
.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  margin: 0 4px;
  border-radius: 50%;
  background: #d6d6d6;
  transition: var(--transition);
}
.owl-theme .owl-dots .owl-dot:hover span, .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--heraBlueColor);
}

.dark-theme {
  background-color: #0d1015;
  color: var(--whiteColor);
}
.dark-theme .h1,
.dark-theme .h2,
.dark-theme .h3,
.dark-theme .h4,
.dark-theme .h5,
.dark-theme .h6,
.dark-theme h1,
.dark-theme h2,
.dark-theme h3,
.dark-theme h4,
.dark-theme h5,
.dark-theme h6 {
  color: var(--whiteColor);
}
.dark-theme p {
  color: #828690;
}
.dark-theme input {
  color: var(--whiteColor);
  background-color: #181b20;
}
.dark-theme input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme a {
  color: var(--whiteColor);
}
.dark-theme a:hover {
  color: var(--heraBlueColor);
}
.dark-theme .default-btn:hover {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card {
  --mdc-elevated-card-container-color: #14171c;
}
.dark-theme .mat-mdc-card .mat-mdc-card-subtitle {
  color: rgba(255, 255, 255, 0.54);
}
.dark-theme .mat-mdc-card.tagus-card {
  box-shadow: unset;
  background-color: #14171c !important;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .btn::before {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn:hover {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .sub-title {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .search-box .input-search {
  background-color: #1e2227;
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .search-box .input-search::placeholder {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .search-box .input-search:focus::placeholder {
  color: transparent;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header .search-box button:hover {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-header.border-bottom {
  border-bottom-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #a9a9c8;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
  color: #828690;
  border-bottom-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info:hover {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-color: #a9a9c8;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .dot-btn {
  color: var(--whiteColor);
  background-color: #1d2024 !important;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row:last-child .mdc-data-table__cell {
  border-bottom-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.recentOrdersTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell a {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell a:hover {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell:first-child {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell:first-child {
  color: var(--heraBlueColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar-thumb {
  border-color: #1f2226;
  background-color: rgba(255, 255, 255, 0.2);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table::-webkit-scrollbar-track {
  background-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
  border-bottom-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
  background-color: #1d2024;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--tertiaryBlueColor) !important;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-text-field-wrapper {
  border-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box i {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box input[type=file] {
  border-color: #1f2226;
  background-color: #14171c;
}
.dark-theme .mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: var(--whiteColor);
  box-shadow: 0px 4px 20px rgba(47, 143, 232, 0.07);
}
.dark-theme .mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  padding: 8px 10px;
  border-bottom: none;
  margin-bottom: 3px;
  background: #f7faff;
  color: var(--blackColor);
  font-weight: 600;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.dark-theme .mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text {
  color: #828690;
  font-size: 14px !important;
  font-family: var(--fontFamily) !important;
}
.dark-theme .mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text .apexcharts-tooltip-text-y-value {
  color: var(--blackColor);
}
.dark-theme .mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-marker {
  top: 0.5px;
  width: 10px;
  height: 10px;
  margin-right: 6px;
}
.dark-theme .mat-mdc-card.tagus-card .mat-mdc-checkbox.mat-accent .mdc-form-field {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
  background-color: #1d2024;
}
.dark-theme .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab .mdc-tab__text-label {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active {
  background-color: var(--heraBlueColor);
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card.create-project-card form .mat-mdc-text-field-wrapper {
  border-color: #1f2226;
}
.dark-theme .mat-mdc-card.tagus-card.create-project-card form .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card.create-project-card form i {
  color: #828690;
}
.dark-theme .mat-mdc-card.tagus-card.create-project-card form input[type=file] {
  border-color: #1f2226;
  background-color: #14171c;
}
.dark-theme .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel {
  background: #1e2227;
}
.dark-theme .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header-title {
  color: var(--whiteColor);
}
.dark-theme .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li {
  border-bottom-color: #1f2226;
}
.dark-theme .mat-mdc-card.bg-main-color {
  background-color: var(--heraBlueColor) !important;
}
.dark-theme .breadcrumb-card .breadcrumb .breadcrumb-item::before {
  background: #a0a0a0;
}
.dark-theme .breadcrumb-card .breadcrumb .breadcrumb-item a {
  color: #bdbdbd;
}
.dark-theme .breadcrumb-card .breadcrumb .breadcrumb-item a:hover {
  color: var(--heraBlueColor);
}
.dark-theme .owl-theme .owl-dots .owl-dot span {
  background: #1e2227;
}
.dark-theme .owl-theme .owl-dots .owl-dot:hover span, .dark-theme .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--heraBlueColor);
}
.dark-theme .chart .apexcharts-datalabel {
  color: var(--whiteColor);
}
.dark-theme .chart .apexcharts-text {
  color: #828690;
  fill: #828690;
}
.dark-theme .chart .apexcharts-pie-label {
  color: var(--whiteColor);
  fill: var(--whiteColor);
}
.dark-theme .chart .apexcharts-tooltip.apexcharts-theme-light {
  background: #1e2227;
}
.dark-theme .chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #0d1015;
  color: var(--whiteColor);
}
.dark-theme .chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text {
  color: #828690;
}
.dark-theme .chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text .apexcharts-tooltip-text-y-value {
  color: var(--whiteColor);
}
.dark-theme .chart .apexcharts-xaxistooltip, .dark-theme .chart .apexcharts-yaxistooltip {
  background-color: #1e2227;
}
.dark-theme .chart .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text, .dark-theme .chart .apexcharts-yaxistooltip .apexcharts-xaxistooltip-text {
  color: var(--whiteColor);
}
.dark-theme .chart .apexcharts-legend-text {
  color: var(--whiteColor) !important;
}
.dark-theme .chart .apexcharts-grid-borders line {
  stroke: rgb(69, 69, 69);
}
.dark-theme .chart .apexcharts-gridlines-horizontal line {
  stroke: rgb(69, 69, 69);
}
.dark-theme .chart .apexcharts-gridlines-vertical line {
  stroke: rgb(69, 69, 69);
}
.dark-theme .chart .apexcharts-yaxis line {
  stroke: rgb(69, 69, 69);
}
.dark-theme .chart .apexcharts-menu {
  background: #1e2227;
  border-color: #1f2226;
}
.dark-theme .chart .apexcharts-menu-icon svg, .dark-theme .chart .apexcharts-reset-icon svg, .dark-theme .chart .apexcharts-zoom-icon svg, .dark-theme .chart .apexcharts-zoomin-icon svg, .dark-theme .chart .apexcharts-zoomout-icon svg {
  fill: var(--whiteColor) !important;
}
.dark-theme .chart .apexcharts-pie line,
.dark-theme .chart .apexcharts-pie circle {
  stroke: rgb(69, 69, 69);
}
.dark-theme .chart .apexcharts-radar-series line,
.dark-theme .chart .apexcharts-radar-series polygon {
  stroke: rgb(69, 69, 69);
}
.dark-theme .chart .apexcharts-title-text {
  fill: var(--whiteColor);
}
.dark-theme .fc .fc-scrollgrid {
  border-color: #1f2226 !important;
}
.dark-theme .fc .fc-daygrid-day-number {
  color: var(--whiteColor);
}
.dark-theme .fc .fc-daygrid-day.fc-day-today {
  background: #1e2227 !important;
}
.dark-theme .fc .fc-h-event .fc-event-main {
  color: var(--whiteColor);
}
.dark-theme .fc.fc-theme-standard td,
.dark-theme .fc.fc-theme-standard th {
  border-color: #1f2226;
}
.dark-theme .NgxEditor__Wrapper {
  border-color: #1f2226 !important;
}
.dark-theme .NgxEditor__Wrapper .NgxEditor__MenuBar {
  background: #14171c;
  border-color: #1f2226 !important;
}
.dark-theme .NgxEditor__Wrapper .NgxEditor {
  background: #14171c;
  color: var(--whiteColor);
}
.dark-theme .NgxEditor__Wrapper .NgxEditor__Seperator {
  border-left-color: #1f2226;
}
.dark-theme quill-editor {
  width: 100%;
}
.dark-theme quill-editor .ql-toolbar.ql-snow {
  border-color: #1f2226;
}
.dark-theme quill-editor .ql-container.ql-snow {
  border-color: #1f2226;
}
.dark-theme quill-editor .ql-editor.ql-blank::before {
  color: rgba(255, 255, 255, 0.6);
}
.dark-theme.card-borderd-theme .mat-mdc-card.tagus-card {
  box-shadow: var(--borderWhiteBoxShadow);
}

.card-borderd-theme .mat-mdc-card.tagus-card {
  box-shadow: var(--borderBoxShadow);
}

.card-border-radius .mat-mdc-card {
  border-radius: 0 !important;
}
.card-border-radius .mat-mdc-card.tagus-card {
  border-radius: 0 !important;
}

.rtl-enabled {
  direction: rtl;
  text-align: right;
}
.rtl-enabled .default-btn {
  padding-left: 31px;
  padding-right: 15px;
}
.rtl-enabled .default-btn i {
  right: auto;
  left: 15px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
  padding-left: 0 !important;
  padding-right: 23px !important;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .btn::before {
  left: auto;
  right: 0;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn {
  padding-left: 25px !important;
  padding-right: 46px !important;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn i {
  left: auto;
  right: 22px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn.small {
  padding-left: 20px !important;
  padding-right: 39px !important;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn.small i {
  left: auto;
  right: 18px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .search-box button {
  right: auto;
  left: 20px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell .mat-mdc-checkbox .mdc-checkbox {
  margin-right: -10px;
  margin-left: 10px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info img {
  margin-right: 0;
  margin-left: 12px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .mat-mdc-checkbox .mdc-checkbox {
  margin-left: 10px;
  margin-right: -10px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span:first-child {
  margin-right: 0;
  margin-left: 2px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .colors span:last-child {
  margin-left: 0;
  margin-right: 2px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .mdc-checkbox {
  margin-left: 5px;
  margin-right: -10px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info {
  padding-left: 0;
  padding-right: 28px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info i,
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info img {
  left: auto;
  right: 0;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
  margin-right: 0;
  margin-left: 25px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info img {
  margin-right: 0;
  margin-left: 12px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info .title {
  margin-left: 0;
  margin-right: 12px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
  margin-right: 0;
  margin-left: 5px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:last-child {
  margin-right: 5px;
  margin-left: 0;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-text-field-wrapper {
  padding-left: 15px;
  padding-right: 60px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .date-input .mat-mdc-text-field-wrapper {
  padding-left: 15px;
  padding-right: 20px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-icon-button.mat-mdc-button-base {
  margin-right: 0;
  margin-left: -15px;
}
.rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box i {
  left: auto;
  right: -38px;
}
.rtl-enabled .mat-mdc-card.tagus-card .status-item #chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-marker {
  margin-right: 0;
  margin-left: 6px;
}
.rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
  margin-right: 0;
  margin-left: 15px;
}
.rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab:last-child {
  margin-right: 15px;
  margin-left: 0;
}
.rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
  margin-right: 0;
  margin-left: -45px;
}
.rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info img {
  margin-right: 0;
  margin-left: 18px;
}
.rtl-enabled .mat-mdc-card.tagus-card.create-project-card form .mat-mdc-text-field-wrapper {
  padding-left: 15px;
  padding-right: 60px;
}
.rtl-enabled .mat-mdc-card.tagus-card.create-project-card form .date-input .mat-mdc-text-field-wrapper {
  padding-left: 15px;
  padding-right: 20px;
}
.rtl-enabled .mat-mdc-card.tagus-card.create-project-card form .mat-mdc-icon-button.mat-mdc-button-base {
  margin-right: 0;
  margin-left: -15px;
}
.rtl-enabled .mat-mdc-card.tagus-card.create-project-card form i {
  right: -38px;
  left: auto;
}
.rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-indicator {
  right: auto;
  left: 25px;
}
.rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header-title,
.rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header-description {
  margin-left: 0;
}
.rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li i {
  left: auto;
  right: 0;
}
.rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li span:nth-child(1) {
  padding-right: 25px;
  padding-left: 0;
}
.rtl-enabled .example-accordion-item {
  display: block;
  border: solid 1px #eeeeee;
}
.rtl-enabled .example-accordion-item + .example-accordion-item {
  border-top: none;
}
.rtl-enabled .example-accordion-item-header {
  display: flex;
  font-weight: 600;
  align-content: center;
  justify-content: space-between;
}
.rtl-enabled .example-accordion-item-header:hover {
  cursor: pointer;
  background-color: #f9f9f9;
}
.rtl-enabled .example-accordion-item-header,
.rtl-enabled .example-accordion-item-body {
  padding: 20px;
}
.rtl-enabled .example-accordion-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.rtl-enabled .example-accordion-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.rtl-enabled .component-dark-theme .example-accordion-item {
  border-color: #1f2226 !important;
}
.rtl-enabled .component-dark-theme .example-accordion-item-header:hover {
  background-color: #1d2024 !important;
}
.rtl-enabled .chart .apexcharts-legend-marker {
  margin-right: 0;
  margin-left: 5px;
}
.rtl-enabled .chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-marker {
  margin-right: 0;
  margin-left: 6px;
}
.rtl-enabled .chart .apexcharts-toolbar {
  right: auto !important;
  left: 0;
}
.rtl-enabled .chart.visitsByDayChart .apexcharts-radar-series,
.rtl-enabled .chart.visitsByDayChart .apexcharts-plot-series {
  direction: ltr;
}
.rtl-enabled .chart .apexcharts-radar-series {
  direction: ltr;
}
.rtl-enabled .chart.sessionsByCountriesChart .apexcharts-yaxis-texts-g {
  direction: ltr;
}
.rtl-enabled .chart.experienceChart .apexcharts-yaxis-texts-g {
  direction: ltr;
}
.rtl-enabled .col {
  margin-right: 0 !important;
  margin-left: 25px !important;
}
.rtl-enabled .col:last-child {
  margin-left: 0 !important;
}
.rtl-enabled .col:last-child .col {
  margin-right: 0 !important;
  margin-left: 25px !important;
}
.rtl-enabled .col:last-child .col:last-child {
  margin-left: 0 !important;
}
.rtl-enabled .col:last-child .col:last-child .col {
  margin-right: 0 !important;
  margin-left: 25px !important;
}
.rtl-enabled .col:last-child .col:last-child .col:last-child {
  margin-left: 0 !important;
}
.rtl-enabled .fc.fc-direction-ltr {
  direction: rtl;
  text-align: right;
}
.rtl-enabled .fc.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-bottom-right-radius: 0.25em;
  border-top-right-radius: 0.25em;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.rtl-enabled .fc.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.rtl-enabled .fc.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-right: 0.75em;
  margin-left: 0;
}
.rtl-enabled .breadcrumb-card .breadcrumb .breadcrumb-item::before {
  right: -13px;
  left: auto;
}
.rtl-enabled .breadcrumb-card .breadcrumb .breadcrumb-item:first-child {
  margin-left: 10px;
  margin-right: 0;
}
.rtl-enabled .breadcrumb-card .breadcrumb .breadcrumb-item:first-child a {
  padding-left: 0;
  padding-right: 22px;
}
.rtl-enabled .breadcrumb-card .breadcrumb .breadcrumb-item:first-child a i {
  left: auto;
  right: 0;
}
.rtl-enabled .breadcrumb-card .breadcrumb .breadcrumb-item:last-child {
  margin-right: 10px;
  margin-left: 0;
}
.rtl-enabled .ql-editor {
  text-align: right;
}
.rtl-enabled .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 0;
}
.rtl-enabled .NgxEditor__Dropdown .NgxEditor__Dropdown--Text:after {
  margin-left: 0;
  margin-right: 24px;
}

@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(4);
  }
}
/* Max width 767px */
@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
  .h1,
  h1 {
    font-size: 36px;
  }
  .h2,
  h2 {
    font-size: 28px;
  }
  .h3,
  h3 {
    font-size: 24px;
  }
  .h4,
  h4 {
    font-size: 20px;
  }
  .h5,
  h5 {
    font-size: 16px;
  }
  .h6,
  h6 {
    font-size: 12px;
  }
  button {
    font-size: 14px !important;
  }
  .default-btn {
    font-size: 13px;
    padding: 11px 31px 11px 15px;
  }
  .default-btn i {
    margin-top: 2px;
    right: 15px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
    border-radius: 5px 5px 0 0;
    width: 250px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header {
    border-radius: 5px 5px 0 0;
    padding: 12px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header h5 {
    font-size: 15px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header button {
    font-size: 13px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li {
    padding: 10px 28px 10px 12px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li img {
    width: 45px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title {
    margin-left: 12px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title h6 {
    font-size: 13.5px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span {
    font-size: 12px;
    padding-left: 16px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span i {
    margin-top: 1px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .close-btn {
    right: 10px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .icon {
    font-size: 25px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .dropdown-item span {
    margin-top: 3px;
    font-size: 13px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body .d-flex.flex-wrap {
    padding: 5px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer {
    padding-top: 11px;
    padding-bottom: 12px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-footer a {
    font-size: 13px;
    padding-right: 15px;
  }
  .profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
    width: 120px;
  }
  .profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li a {
    font-size: 14px;
    padding: 6px 10px 6px 31px;
  }
  .profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li a i {
    left: 10px;
  }
  .profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content {
    padding: 5px 0;
  }
  .language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel {
    width: 130px;
  }
  .language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li button {
    padding: 8px 15px 8px 40px;
    font-size: 14px;
  }
  .language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li button img {
    width: 18px;
    left: 15px;
  }
  .monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel {
    width: 135px;
  }
  .monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel .mat-mdc-menu-content button {
    padding: 5px 15px;
    font-size: 14px;
  }
  .monthMenu.mat-mdc-menu-panel.mat-mdc-menu-panel.dotMenu .mat-mdc-menu-content button {
    padding-left: 35px;
  }
  .mat-mdc-card.tagus-card {
    padding: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header {
    margin-bottom: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
    padding: 0 0 2px 18px !important;
    font-size: 14px !important;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .btn::before {
    font-size: 12px;
    margin-top: -1px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .dot-btn {
    top: 1px;
    font-size: 22px !important;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header.border-bottom {
    padding-bottom: 15px;
    border-bottom: 1px solid #eef0f7;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn {
    padding: 12px 18px 12px 35px !important;
    font-size: 13px !important;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn i {
    left: 15px;
    font-size: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    padding: 12px 15px;
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell::after {
    height: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    font-size: 13.5px;
    padding: 12px 15px;
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    margin-right: 0;
    padding-right: 35px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info img {
    width: 40px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
    font-size: 13px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-paginator-outer-container {
    margin-top: 5px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-paginator-outer-container .mat-mdc-paginator-container .mat-mdc-icon-button.mat-mdc-button-base {
    width: 38px;
    height: 38px;
    padding: 5px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    padding-left: 15px;
    padding-right: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info {
    padding-left: 27px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info i,
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info img {
    font-size: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-right: 35px;
    margin-right: 0;
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info img {
    width: 42px;
    margin-right: 12px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info span {
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    padding: 12px 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
    padding: 4px 18px 5px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .name-info {
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-right: 40px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group {
    margin-top: 20px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header {
    margin-bottom: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
    font-size: 15px;
    padding: 12px 20px;
    margin-right: 10px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-text-field-wrapper {
    padding: 0 15px 0 45px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box i {
    top: 16px;
    left: -30px;
    font-size: 23px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .default-btn {
    font-size: 15px !important;
    padding: 15px 35px !important;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
    font-size: 15px;
    padding: 12px 20px;
    margin-right: 10px;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
    margin-right: 0;
    display: block !important;
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info img {
    display: block;
    margin-right: 0;
    margin-bottom: 12px;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info a {
    max-width: 100%;
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .badge {
    padding: 4px 15px 5px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-header {
    padding: 15px;
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-indicator {
    right: 15px;
    top: 12px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-indicator::after {
    padding: 3px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body {
    padding: 5px 15px 15px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li i {
    font-size: 15px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li span:nth-child(1) {
    padding-left: 19px;
  }
  .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel:last-child {
    margin-bottom: 15px;
  }
  .mat-mdc-card.tagus-card.create-project-card form .mat-mdc-text-field-wrapper {
    padding: 0 15px 0 45px;
  }
  .mat-mdc-card.tagus-card.create-project-card form i {
    top: 16px;
    left: -30px;
    font-size: 23px;
  }
  .mat-mdc-card.tagus-card.create-project-card .default-btn {
    font-size: 15px !important;
    padding: 15px 35px !important;
  }
  .chart .apexcharts-datalabel {
    font-size: 13px;
  }
  .chart .apexcharts-text {
    font-size: 13px;
  }
  .chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    font-size: 13px !important;
  }
  .chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-text {
    font-size: 13px !important;
  }
  .chart .apexcharts-xaxistooltip .apexcharts-xaxistooltip-text,
  .chart .apexcharts-yaxistooltip .apexcharts-xaxistooltip-text {
    font-size: 14px !important;
  }
  .chart.liveVisitsChart .apexcharts-text {
    font-size: 13px;
  }
  .chart.liveVisitsChart .apexcharts-tooltip .apexcharts-tooltip-text {
    font-size: 14px !important;
  }
  .chart.visitsByDayChart {
    margin-bottom: -30px;
  }
  .breadcrumb-card .breadcrumb {
    margin-top: 12px;
  }
  .create-dialog-box .title {
    padding: 15px;
  }
  .create-dialog-box .title .close-btn {
    font-size: 14px !important;
  }
  .create-dialog-box form {
    padding: 15px;
  }
  .create-dialog-box form .form-group {
    margin-bottom: 15px;
  }
  .create-dialog-box form .form-group .input-folder-name {
    height: 48px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .create-dialog-box form .form-group .input-control {
    height: 48px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .create-dialog-box form .form-group .input-control[type=file] {
    line-height: 50px;
  }
  .create-dialog-box form button {
    padding: 14px 22px 14px 41px;
    font-size: 13px !important;
  }
  .create-dialog-box form button i {
    left: 20px;
    font-size: 16px;
  }
  .create-dialog-box.scrollable-dialog {
    max-height: 65vh;
  }
  .create-dialog-box iframe {
    height: 140px;
  }
  .fc .fc-toolbar-title {
    font-size: 1.3em !important;
  }
  .fc .fc-daygrid-block-event .fc-event-time,
  .fc .fc-daygrid-block-event .fc-event-title {
    padding: 0 5px;
    font-size: 13px;
    white-space: nowrap;
  }
  .rtl-enabled .default-btn {
    padding-left: 11px;
    padding-right: 15px;
  }
  .rtl-enabled .default-btn i {
    right: auto;
    left: 15px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
    padding-left: 0 !important;
    padding-right: 18px !important;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn {
    padding-left: 18px !important;
    padding-right: 35px !important;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .default-btn i {
    left: auto;
    right: 15px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    margin-left: 0;
    padding-left: 35px;
    padding-right: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info img {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .role-info {
    padding-left: 0;
    padding-right: 27px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    margin-left: 0;
    padding-left: 35px;
    padding-right: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info img {
    margin-right: 0;
    margin-left: 12px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-left: 40px;
    padding-right: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .product-tabs.mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
    margin-right: 0;
    margin-left: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box .mat-mdc-text-field-wrapper {
    padding-left: 15px;
    padding-right: 45px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .note-box i {
    left: auto;
    right: -30px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
    margin-right: 0;
    margin-left: 10px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
    margin-left: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info img {
    margin-left: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-indicator {
    right: auto;
    left: 15px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .course-details-desc .mat-accordion .mat-expansion-panel-body .content-list li span:nth-child(1) {
    padding-left: 0;
    padding-right: 19px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.create-project-card form .mat-mdc-text-field-wrapper {
    padding-left: 15px;
    padding-right: 45px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.create-project-card form i {
    right: -30px;
    left: auto;
  }
  .rtl-enabled .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child {
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child {
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child .col:last-child {
    margin-left: 0 !important;
  }
}
/* Min width 576px to Max width 767px */
/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 14px;
  }
  p {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
  button {
    font-size: 14px !important;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header h5 {
    font-size: 16px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-header button {
    font-size: 13px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title {
    margin-left: 12px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title h6 {
    font-size: 14px;
  }
  .menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel .menu-body li .title span i {
    margin-top: 1px;
  }
  .profile-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li a {
    font-size: 14px;
  }
  .language-menu-dropdown.mat-mdc-menu-panel.mat-mdc-menu-panel ul li button {
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card {
    padding: 20px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header {
    margin-bottom: 20px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
    padding: 0 0 2px 23px !important;
    font-size: 14px !important;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header .btn::before {
    font-size: 14px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-header.border-bottom {
    padding-bottom: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    padding: 15px 20px;
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell::after {
    height: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    padding: 15px 20px;
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    margin-right: 0;
    padding-right: 35px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info img {
    width: 45px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-right: 35px;
    margin-right: 0;
    font-size: 15px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-right: 40px;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mat-mdc-tab {
    padding: 14px 25px;
    font-size: 16px;
  }
  .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
    margin-right: 0;
  }
  .fc .fc-daygrid-block-event .fc-event-time,
  .fc .fc-daygrid-block-event .fc-event-title {
    padding: 0 3px;
    font-size: 13px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
    padding-left: 0 !important;
    padding-right: 23px !important;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    margin-left: 0;
    padding-left: 35px;
    padding-right: 0;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    margin-left: 0;
    padding-right: 0;
    padding-left: 35px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-right: 0;
    padding-left: 40px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
    margin-left: 0;
  }
  .rtl-enabled .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child {
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child {
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child .col:last-child {
    margin-left: 0 !important;
  }
}
/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    padding-right: 25px;
  }
  .fc .fc-daygrid-block-event .fc-event-time,
  .fc .fc-daygrid-block-event .fc-event-title {
    padding: 0 10px;
    font-size: 14px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-header .btn {
    padding-left: 0 !important;
    padding-right: 23px !important;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    margin-left: 0;
    padding-right: 0;
    padding-left: 35px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    margin-right: 0;
    margin-left: 35px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.invoiceListsTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    padding-right: 0;
    padding-left: 40px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card.enrolled-created-box .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content .mat-elevation-z8 .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .course-info {
    margin-left: 0;
  }
  .rtl-enabled .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child {
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child {
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child .col {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .rtl-enabled .col:last-child .col:last-child .col:last-child {
    margin-left: 0 !important;
  }
}
/* Min width 1200px to Max width 1399px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    white-space: nowrap;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    padding-right: 25px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    padding-right: 0;
    padding-left: 25px;
  }
}
/* Min width 1600px */
@media only screen and (min-width: 1600px) {
  .default-btn {
    font-size: 14px;
    padding: 11px 42px 11px 22px;
  }
  .default-btn i {
    right: 22px;
    margin-top: 1px;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    margin-right: -80px;
    padding-right: 0;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable {
    overflow-x: auto;
    overflow-y: hidden;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    margin-right: -80px;
    padding-right: 0;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.myTasksTable .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell {
    padding: 15px 20px;
  }
  .rtl-enabled .default-btn {
    padding-left: 42px;
    padding-right: 22px;
  }
  .rtl-enabled .default-btn i {
    right: auto;
    left: 22px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .product-info {
    padding-left: 0;
    margin-right: 0;
    margin-left: -80px;
  }
  .rtl-enabled .mat-mdc-card.tagus-card .mat-mdc-card-content .tagus-mat-table.teamMembersListTable .mat-mdc-table .mdc-data-table__content .mdc-data-table__row .mdc-data-table__cell .user-info {
    margin-right: 0;
    margin-left: -80px;
  }
}
table,
.table {
  width: 100%;
  border-collapse: collapse;
}
table thead tr th,
.table thead tr th {
  background-color: #f7faff;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  position: relative;
  padding: 15px 20px;
}
table thead tr th::after,
.table thead tr th::after {
  top: 50%;
  right: 0;
  width: 1px;
  content: "";
  height: 17px;
  position: absolute;
  background-color: #e7ebf5;
  transform: translateY(-50%);
}
table thead tr th:last-child::after,
.table thead tr th:last-child::after {
  display: none;
}
table thead tr th:first-child,
.table thead tr th:first-child {
  text-align: center;
}
table tbody tr td,
.table tbody tr td {
  padding: 18px 20px;
  text-align: center;
  white-space: nowrap;
  border-bottom-color: #f7faff;
  border-bottom: 1px solid #f7faff;
  justify-content: center;
}
table tbody tr td .name-info img,
.table tbody tr td .name-info img {
  width: 50px;
  justify-content: center;
}
table tbody tr td .name-info h5,
.table tbody tr td .name-info h5 {
  font-size: 15px;
  margin-left: 10px;
}
table tbody tr td .dot-btn,
.table tbody tr td .dot-btn {
  width: 30px;
  line-height: 30px;
  padding: 0 !important;
  height: 30px !important;
  color: var(--blackColor);
  font-size: 18px !important;
  min-width: unset !important;
  border-radius: 4px !important;
  display: inline-block !important;
  background-color: #f3f6f9 !important;
}
table tbody tr td:first-child,
.table tbody tr td:first-child {
  text-align: center;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Estilso Ripple */
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected__ripple: blue !important;
  --mdc-checkbox-selected-focus-icon-color: var(--tertiaryBlueColor);
  --mdc-checkbox-selected-hover-icon-color: var(--primaryOrangeColor);
  --mdc-checkbox-selected-icon-color: var(--tertiaryBlueColor);
  --mdc-checkbox-selected-pressed-icon-color: var(--primaryOrangeColor);
}

/* TODO Borrar */
body .login-page {
  background-color: var(--primaryBlueColor) !important;
}